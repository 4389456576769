import React, { useState, useEffect } from "react";
import { Pagination } from "antd";
import "./searchresults.css";
import HotelObject from "../HotelObject/hotelobject";
import HotelsFilters from "./HotelFilters/hotelfilters";
import PackageFilter from "./PackageFilter/PackageFilter";
import cardsSVG from "../../../../assets/images/search/cards.svg";
import listSVG from "../../../../assets/images/search/list.svg";

import {
  HOTELS,
  ORGANIZED_TOURS,
  PACKAGES,
  useSearch,
} from "../../../../contexts/SearchContext";
import PackageObject from "../PackageObject/packageobject";
import { Loader } from "../../../Loader/Loader";
import Timer from "../../../../Pages/Timer/Timer";
import TourObject from "../OrganizedTourObject/tourObject";
import OrganizedTourFilter from "./OrganizedTourFilter";

const SearchResults = () => {
  const {
    getSearchPhrase,
    searchResults,
    sessionId,
    searchTab,
    error,
    loading,
    rangeSearchResults,
  } = useSearch();

  const searchPhrase = getSearchPhrase();
  const [filters, setFilters] = useState(null);

  const initialPageIndex = 0;
  const [isMobile, setIsMobile] = useState(false);
  const [isCards, setIsCards] = useState(true);
  const [pageIndex, setPageIndex] = useState(initialPageIndex);
  const [searchResultsFiltered, setSearchResultsFiltered] =
    useState(searchResults);
  const [totalResults, setTotalResults] = useState(0);
  const [initialLoadedItems, setInitialLoadedItems] = useState(50);

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setIsMobile(true);
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setIsMobile(true);
    }
  }, [window.innerWidth]);

  useEffect(() => {
    setSearchResultsFiltered(searchResults);
    setPageIndex(0);
    setTotalResults(searchResults?.length || 0);
  }, [searchResults]);

  function renderResults() {
    if (loading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Loader showText />
        </div>
      );
    }

    return (
      <>
        {searchResultsFiltered
          ?.slice(
            pageIndex * initialLoadedItems,
            initialLoadedItems * (pageIndex + 1)
          )
          ?.map((searchItem, i) => {
            return (
              <React.Fragment key={i}>
                {/*{searchTab === PLANES && <FlightObject flightData={searchItem} />}*/}
                {searchTab === HOTELS && (
                  <HotelObject
                    key={HOTELS + "-" + i}
                    hotelsItem={searchItem}
                    searchPhrase={searchPhrase}
                    searchSession={sessionId}
                  />
                )}
                {searchTab === PACKAGES && (
                  <PackageObject key={PACKAGES + "-" + i} {...searchItem} />
                )}
                {searchTab === ORGANIZED_TOURS && (
                  <TourObject key={ORGANIZED_TOURS + "-" + i} {...searchItem} />
                )}
              </React.Fragment>
            );
          })}
        {totalResults > 0 ? (
          <Pagination
            defaultCurrent={1}
            total={totalResults}
            pageSize={initialLoadedItems}
            onChange={(page, pageSize) => {
              // console.log("page", page);
              setPageIndex(page - 1);
              setInitialLoadedItems(pageSize);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "20px",
              width: "100%",
            }}
          />
        ) : null}
      </>
    );
  }

  function renderFilters() {
    if (searchTab === HOTELS)
      return (
        <HotelsFilters
          setSearchResultsFiltered={setSearchResultsFiltered}
          searchResultsFiltered={searchResultsFiltered}
          searchResults={searchResults}
          filters={filters}
        />
      );
    if (searchTab === PACKAGES)
      return (
        <PackageFilter
          setSearchResultsFiltered={setSearchResultsFiltered}
          searchResultsFiltered={searchResultsFiltered}
          searchResults={searchResults}
        />
      );
    if (searchTab === ORGANIZED_TOURS)
      return (
        <OrganizedTourFilter
          setSearchResultsFiltered={setSearchResultsFiltered}
          searchResultsFiltered={searchResultsFiltered}
          searchResults={searchResults}
        />
      );
  }

  return (
    <>
      {(searchTab === PACKAGES || searchTab === HOTELS) &&
      searchResultsFiltered?.length ? (
        <Timer />
      ) : (
        <></>
      )}
      <div className="tw-flex tw-flex-col lg:tw-flex-row tw-h-full tw-gap-[20px]">
        {totalResults > 0 ? (
          <div className="lg:tw-h-[80%] tw-bg-white lg:tw-w-[25%] tw-shadow-[0px_1px_2px_#b5b3b3]">
            {renderFilters()}
          </div>
        ) : (
          <></>
        )}

        <div
          className={
            `searchResults-item searchResults-results` +
            (isCards & !isMobile ? " cards" : "")
          }
        >
          <div
            className={
              "change-layout" + (searchResultsFiltered?.length ? "" : " hidden")
            }
          >
            <button
              className="layout-switch"
              onClick={() => setIsCards(!isCards)}
            >
              {isCards ? (
                <img src={listSVG} alt="cards" className="icon" />
              ) : (
                <img src={cardsSVG} alt="cards" className="icon" />
              )}
            </button>
          </div>
          {error || renderResults()}
          {!rangeSearchResults ? null : (
            <div>
              <div
                style={{ height: 10, width: "100%", backgroundColor: "red" }}
              />
              {/* {renderRangeResults()} */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchResults;
