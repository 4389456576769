import React, { useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { FaBed } from "react-icons/fa";
import { FaCircleInfo } from "react-icons/fa6";

import { MdOutlineFlight } from "react-icons/md";
import "../OrganizedTourProfile.scss";
import "../../Profiles.scss";
import FlightsFooter from "../../../../Components/Agent/SearchAgent/PackageObject/FlightsFooter";
import RoomsSelector from "../../../../Components/InfoBox/RoomsSelector";
import AdditionalInfo from "../../../../Components/InfoBox/AdditionalInfo";
import { useSelectedResult } from "../../../../contexts/SelectedResultContext";
import PassengersData from "../../../../Components/PassengersData/PassengersData";
import TermsAndConditions from "../../../TermsAndConditions/TermsAndConditions";
import { useOnClickOutside } from "../../../../utils/ComponentsUtils";
import { IoSchool, IoSearchSharp } from "react-icons/io5";
import Supplement from "../../../../Components/InfoBox/Supplement";
import { useAppContext } from "../../../../contexts/AppContext";
import HandlingFeePicker from "../../../../Components/Agent/PaymentAgent/HandlingFeePicker/HandlingFeePicker";
import { Loader } from "../../../../Components/Loader/Loader";

const PackageDetails = memo(
  ({ createCustomerPriceQuote, leftToPay, resourceURL }) => {
    const { isAppLoggedIn } = useAppContext();
    const disabled = !isAppLoggedIn();
    ///
    const {
      selectedResult,
      isConditionsConfirmed,
      setIsConditionsConfirmed,
      handleFeeData,
      updateHandleFeeData,
      agentData,
      hasLdsSupplements,
      ourSavedLDSOrder,
    } = useSelectedResult();

    const { t } = useTranslation();
    const [isTermsModal, setIsTermsModal] = useState(false);

    const modalRef = React.createRef();
    useOnClickOutside(modalRef, () => setIsTermsModal(false));

    const {
      clientRemarks,
      hotel,
      linkedFlights: { flight } = {},
      // hotelAllocation,
    } = selectedResult || {};

    // const isAutoApprove = hotelAllocation === "true";

    if (!selectedResult) return <Loader />;
    return (
      <>
        {!disabled && (
          <div className={"data-box "}>
            <div className="data-box-header-agent">
              <IoSchool className="header-icon" />
              ניהול חבילה (סוכן)
            </div>
            <div className={"tw-flex tw-flex-col lg:tw-flex-row"}>
              <div className="data-box-body tw-flex tw-justify-start tw-flex-1">
                <HandlingFeePicker
                  extendHandleFeeDefault={true}
                  handleFeeData={handleFeeData}
                  updateHandleFeeData={updateHandleFeeData}
                />
              </div>
              <div className="data-box-body tw-flex-col tw-justify-start  tw-flex-1">
                <div className="tw-m-4 tw-flex-col tw-space-y-4">
                  <div>
                    <button
                      className="button  "
                      onClick={() =>
                        createCustomerPriceQuote({ moveNextStateFlag: false })
                      }
                    >
                      {ourSavedLDSOrder
                        ? "שמור הצעת מחיר ללקוח"
                        : "קישור להצעת מחיר ללקוח"}
                    </button>
                  </div>
                  {ourSavedLDSOrder && (
                    <div className="tw-flex flex-col items-center p-4 space-y-4">
                      <div className="tw-flex-grow w-full max-w-md tw-flex">
                        <input
                          type="text"
                          className="w-full p-2 border border-gray-300 rounded tw-flex-1"
                          value={window.location.href}
                        />
                      </div>
                    </div>
                  )}
                  {ourSavedLDSOrder && (
                    <button
                      className=" button"
                      onClick={() => {
                        navigator.clipboard
                          .writeText(window.location.href)
                          .then(() => {
                            alert("קישור הועתק");
                          });
                      }}
                    >
                      העתק קישור לקליפבורד
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {agentData && (
          <div className={"data-box"}>
            <div className="data-box-header">
              <IoSearchSharp className="header-icon" />
              פרטי הסוכן
            </div>
            <div
              className="data-box-body-outer"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <div className="data-box-body">
                <div>
                  סוכן:&nbsp;
                  {agentData.firstname} {agentData.lastname}
                </div>
                <div>
                  טלפון:&nbsp;
                  {agentData.cell}
                </div>
                <div>
                  מייל:&nbsp;
                  {agentData.email}
                </div>
                <div>
                  מספר ההצעה:&nbsp;
                  {selectedResult.dealId}
                </div>
              </div>
              {ourSavedLDSOrder?.agent?.companylogobase65 && (
                <img
                  src={ourSavedLDSOrder.agent.companylogobase65}
                  alt="סוכנות נסיעות"
                  style={{
                    height: "100px",
                    maxWidth: "600px",
                    marginLeft: "10px",
                    marginBottom: "10px",
                  }}
                />
              )}
            </div>
          </div>
        )}

        <div className={"data-box"}>
          <div className="data-box-header">
            <IoSearchSharp className="header-icon" />
            פרטי הטיול
          </div>
          <div className="data-box-body">
            {resourceURL ? (
              <embed src={resourceURL} width="100%" height="800px" />
            ) : (
              <div className="no-data">אין מידע זמין</div>
            )}
          </div>
        </div>

        <div className={"data-box"}>
          <div className="data-box-header">
            <MdOutlineFlight className="header-icon" />
            פרטי הטיסה
          </div>
          <div className="data-box-body">
            <FlightsFooter flights={flight} />
          </div>

          {/*  todo(sagiv): Prettier*/}
        </div>

        <div className={"data-box"}>
          <div className="data-box-header">
            <FaBed className="header-icon" />
            פרטי הנוסעים
          </div>
          <PassengersData
            allDisabled={disabled}
            returnDate={hotel?.checkOutDate || undefined}
            isTour={true}
          />
          ,
        </div>

        {hasLdsSupplements() ? (
          <div className={"data-box"}>
            <div className="data-box-header">
              <FaBed className="header-icon" />
              {t("packages.supplement")}
            </div>

            <div className="data-box-body">
              <Supplement disabled={disabled} />
            </div>
          </div>
        ) : null}

        <div className={"data-box"}>
          <div className="data-box-header">
            <FaCircleInfo className="header-icon" />

            {t("packages.additionalInfo")}
          </div>
          <div className="data-box-body">
            <AdditionalInfo innerHtml={clientRemarks} />
          </div>
        </div>

        <div
          className="passangerData-checkout-checkbox-wrapper"
          style={{ fontSize: "20px" }}
        >
          <input
            type="checkbox"
            className="passangerData-checkout-checkbox"
            required
            onChange={(e) => setIsConditionsConfirmed(e.target.checked)}
            checked={isConditionsConfirmed}
          ></input>
          <span className="passangerData-checkout-checkbox-text-black">
            {`${t("completepassengers.approve")}`}
          </span>
          <span
            className="passangerData-checkout-checkbox-text"
            onClick={() => setIsTermsModal(true)}
          >
            התקנון
          </span>
        </div>
        {isTermsModal ? (
          <div className="passangerData-popup-wrapper">
            <div ref={modalRef} className="passangerData-popup-frame">
              <TermsAndConditions />

              <div className="passangerData-popup-agree-btn-wrapper">
                <button
                  type="button"
                  className="passangerData-popup-agree-btn"
                  onClick={() => setIsTermsModal(false)}
                >
                  {t("completepassengers.close")}
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
);

export default PackageDetails;
