import axios from "axios";

// Global Variables
import { ApiReqBaseUrl } from "../assets/GlobalVariables";
import { performGetFetch, performPostFetch, performPostFetchThrowError } from "./fetchService";

// --- Functions

// -- Finance And Order - API Requests

// Get finance data
const getFinanceData = async (data) => {
  // Create API request for finance data
  return performPostFetch(
    `${ApiReqBaseUrl}/getBasicFinancials`,
    data,
    "אירעה שגיאה בשליפת המידע הפיננסי"
  );
};

// Get orders data
const getOrders = async (data) => {
  // Create API request for orders data
  try {
    return await axios.post(`${ApiReqBaseUrl}/getUpcomingOrders`, data);
  } catch (err) {
    console.log({ err });
  }
};

const getFutureOrdersCommission = async (data) => {
  // Create API request for future orders commission
  try {
    return await axios.post(`${ApiReqBaseUrl}/futureOrdersCommission`, data);
  } catch (err) {
    console.log({ err });
  }
};

export const getAvailableOrdersCommission = async (data) => {
  // Create API request for available orders commission
  try {
    return await axios.post(`${ApiReqBaseUrl}/availableOrdersCommission`, data);
  } catch (err) {
    console.log({ err });
  }
};

export const getOrdersCommissionHistory = async (data) => {
  // Create API request for available orders commission
  try {
    return await axios.post(`${ApiReqBaseUrl}/ordersCommissionHistory`, data);
  } catch (err) {
    console.log({ err });
  }
};


export const cancelOrderByOrderId = async (data) => {
  try {
    return await axios.post(`${ApiReqBaseUrl}/cancelOrder`, data);
  } catch (err) {
    console.log({ err });
  }
};

const getOrdersByEmail = async (data) => {
  return performPostFetch(`${ApiReqBaseUrl}/getOrders`, data);
};

const getFinancesByEmail = async (data) => {
  try {
    return await axios.post(`${ApiReqBaseUrl}/getFinanceByEmail`, data);
  } catch (err) {
    console.log({ err });
  }
};

const approveFinanceRequest = async ({ email, reqNumber }) => {
  try {
    return await axios.get(
      `${ApiReqBaseUrl}/approveFinanceRequest/${email}/${reqNumber}`
    );
  } catch (err) {
    console.log({ err });
  }
};

const declineFinanceRequest = async ({ email, reqNumber }) => {
  try {
    return await axios.get(
      `${ApiReqBaseUrl}/declineFinanceRequest/${email}/${reqNumber}`
    );
  } catch (err) {
    console.log({ err });
  }
};

const setFinanceRequestComments = async (data) => {
  try {
    return await axios.post(`${ApiReqBaseUrl}/setFinanceRequestComments`, data);
  } catch (err) {
    console.log({ err });
  }
};

const getAllUsersEmail = async () => {
  return performGetFetch(
    `${ApiReqBaseUrl}/getAllAgentsEmails`,
    {},
    "אירעה שגיאה בשליפת האימיילים"
  );
};

const getPackagesOrders = async (agentId) => {
  return performPostFetch(
    `${ApiReqBaseUrl}/getPackagesOrdersByAgentId`,
    { agent_id: agentId },
    "אירעה שגיאה בשליפת ההזמנות"
  );
};

const createFinance = async (data) => {
  return performPostFetch(`${ApiReqBaseUrl}/createFinanceRequest`, data);
};

// -- Hotels - API Requests

// Get search hotel results
const searchHotels = async (data) => {
  return performPostFetch(`${ApiReqBaseUrl}/searchHotels`, data);
};

const searchLds = async (data) => {
  return performPostFetch(`${ApiReqBaseUrl}/searchLds`, data);
};

const getOrgTourInfo = async (id) => {
  return performGetFetch(`${ApiReqBaseUrl}/getOrgTourInfo/${id}`);
};

const getActiveDestinations = async (organizedTours = false) => {
  return performGetFetch(`${ApiReqBaseUrl}/getActiveDestinations?organized=${organizedTours}`, {
    organizedTours,
  });
}

const getLdsDates = async (data) => {
  return performPostFetch(`${ApiReqBaseUrl}/getLdsDates`, data);
};

const searchHotelsBasis = async (data) => {
  // Create API request for hotel search
  try {
    return await axios.post(`${ApiReqBaseUrl}/searchHotelsBasis`, data);
  } catch (err) {
    console.log({ err });
  }
};

// Get hotel profile data
const getHotelsProfileData = async (data) => {
  return performPostFetch(`${ApiReqBaseUrl}/hotelInformation`, data);
};

// Get hotel image
const getHotelsImage = async (data) => {
  // Create API request for hotel profile main image
  try {
    return await axios.post(`${ApiReqBaseUrl}/getImage`, data);
  } catch (err) {
    console.log({ err });
  }
};

const getHotelsProfileImages = async (data) => {
  // Create API request for hotels profileimages
  try {
    return await axios.post(`${ApiReqBaseUrl}/hotelImages`, data);
  } catch (err) {
    console.log({ err });
  }
};

const getHotelsDescription = async (data) => {
  // Create API request for hotel's description
  try {
    return await axios.post(`${ApiReqBaseUrl}/hotelDescriptions`, data);
  } catch (err) {
    console.log({ err });
  }
};

const bookHotel = async (data) => {
  // Create API request for booking
  try {
    return await axios.post(`${ApiReqBaseUrl}/bookHotel`, data);
  } catch (err) {
    console.log({ err });
  }
};

const newHotelOrder = async (data) => {
  // Create API request for new hotel order
  try {
    return await axios.post(`${ApiReqBaseUrl}/newHotelOrder`, data);
  } catch (err) {
    console.log({ err });
  }
};

// Get delay payed order data
const getDelayedPayOrder = async (data) => {
  // Create API request for delay payed order data
  try {
    return await axios.post(`${ApiReqBaseUrl}/orderByID`, data);
  } catch (err) {
    console.log({ err });
  }
};

const updateOrderFinancials = async (data) => {
  try {
    return await axios.post(`${ApiReqBaseUrl}/updateOrderFinancials`, data);
  } catch (err) {
    console.log({ err });
  }
};

// Delayed payment
const delayedPayment = async (data) => {
  // Create API request for delayed payment
  try {
    return await axios.post(`${ApiReqBaseUrl}/delayedPayment`, data);
  } catch (err) {
    console.log({ err });
  }
};

// Check In Info
const checkInInfo = async (data) => {
  // Create API request for check in info
  try {
    return await axios.post(`${ApiReqBaseUrl}/checkInInfo`, data);
  } catch (err) {
    console.log({ err });
  }
};

// Get cancellation Policies
const cancellationPolicies = async (data) => {
  // Create API request for cancellationPolicies
  try {
    return await axios.post(`${ApiReqBaseUrl}/cancellationPolicies`, data);
  } catch (err) {
    console.log({ err });
  }
};

// Get Facilities of hotel
const getFacilities = async (data) => {
  // Create API request for getFacilities
  try {
    return await axios.post(`${ApiReqBaseUrl}/getFacilities`, data);
  } catch (err) {
    console.log({ err });
  }
};

// Pay
const pay = async (data) => {
  // Pay
  try {
    return await axios.post(`${ApiReqBaseUrl}/pay`, data);
  } catch (err) {
    console.log({ err });
  }
};

const sendSuccessOrder = async (base64Image, mail) => {
  try {
    return await axios.post(`${ApiReqBaseUrl}/mailOrder`, {
      mail,
      base64Image,
    });
  } catch (err) {
    console.log({ err });
  }
};

const getAgents = async () => {
  return performGetFetch(`${ApiReqBaseUrl}/getAgents`);
};

const activateAgent = async (agentId) => {
  return performGetFetch(`${ApiReqBaseUrl}/activateAgent/${agentId}`);
};

const getAgentRecommendedByCount = async (id, image) => {
  return performGetFetch(`${ApiReqBaseUrl}/getAgentRecommendedByCount`);
};

const getHotelInfo = async (data) => {
  return performPostFetch(`${ApiReqBaseUrl}/getHotelInfo`, data);
};

const updateLdsHandlingfee = async (data) => {
  return performPostFetch(`${ApiReqBaseUrl}/setLdsHandlingfee`, data);
};

const updateCurrency = async (data) => {
  return performPostFetch(`${ApiReqBaseUrl}/updateCurrency`, data);
};

const getLdsOrder = async (data) => {
  return performPostFetch(`${ApiReqBaseUrl}/getLdsOrder`, data);
};
const getLDSOrderById = async (data) => {
  return performPostFetch(`${ApiReqBaseUrl}/findLDSById`, data);
};
const createLdsNewOrder = async (data) => {
  return performPostFetch(`${ApiReqBaseUrl}/createLdsNewOrder`, data);
};
const updateLdsOrder = async (data) => {
  // console.log("updateLdsOrder",data)
  return performPostFetch(`${ApiReqBaseUrl}/updateLdsOrder`, data);
};

const findExtendedDeals = async (data) =>
  performPostFetch(`${ApiReqBaseUrl}/findExtendedDeals`, data);

const payLds = async (data) =>
  performPostFetchThrowError(`${ApiReqBaseUrl}/payLds`, data);

const createLdsPaymentLink = async (currentCard, orderId) =>
  fetch(ApiReqBaseUrl + "/getLDSPaymentUrl", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      amount:
        currentCard.numberofpayments && currentCard.numberofpayments > 1
          ? currentCard.amountWithInterest
          : currentCard.amount,
      actualAmount: currentCard.amount,
      currency: "ILS",
      orderId: orderId,
      tash: currentCard.numberofpayments,
    }),
  })

const removeLdsPaymentLink = async (paymentLink, orderId) =>
  fetch(ApiReqBaseUrl + "/removeLDSPaymentUrl", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      paymentLink: paymentLink.url,
      orderId,
    }),
  });
const loadInterestTable = async () => {
  return performGetFetch(`${ApiReqBaseUrl}/loadInterestTable`);
};
const saveInterestTable = async (data) => {
  return performPostFetch(`${ApiReqBaseUrl}/saveInterestTable`, data);
};

// const loadCreditCardFee = async () => {
//   return performGetFetch(`${ApiReqBaseUrl}/loadCreditCardFee`);
// };

// const saveCreditCardFee = async (data) => {
//   return performPostFetch(`${ApiReqBaseUrl}/saveCreditCardFee`, data);
// };

const loadGlobalConstant = async () => {
  return performGetFetch(`${ApiReqBaseUrl}/loadGlobalConstant`);
};

const saveGlobalConstant = async (data) => {
  return performPostFetch(`${ApiReqBaseUrl}/saveGlobalConstant`, data);
};

const loadLDSSuppliers = async () => {
  return performGetFetch(`${ApiReqBaseUrl}/loadLDSSuppliers`);
};

const saveLDSSuppliers = async (data) => {
  return performPostFetch(`${ApiReqBaseUrl}/saveLDSSuppliers`, data);
};

export {
  removeLdsPaymentLink,
  searchHotels,
  searchHotelsBasis,
  createLdsPaymentLink,
  getHotelsProfileData,
  getFinanceData,
  getOrders,
  getHotelsImage,
  getHotelsProfileImages,
  getHotelsDescription,
  bookHotel,
  newHotelOrder,
  getDelayedPayOrder,
  delayedPayment,
  checkInInfo,
  cancellationPolicies,
  getFacilities,
  pay,
  getOrdersByEmail,
  sendSuccessOrder,
  getFinancesByEmail,
  createFinance,
  approveFinanceRequest,
  declineFinanceRequest,
  setFinanceRequestComments,
  getAllUsersEmail,
  getAgents,
  getAgentRecommendedByCount,
  updateOrderFinancials,
  searchLds,
  getLdsDates,
  getActiveDestinations,
  getHotelInfo,
  getLdsOrder,
  getLDSOrderById,
  createLdsNewOrder,
  findExtendedDeals,
  updateCurrency,
  payLds,
  updateLdsHandlingfee,
  getPackagesOrders,
  activateAgent,
  loadInterestTable,
  saveInterestTable,
  // loadCreditCardFee,
  // saveCreditCardFee,
  loadGlobalConstant,
  saveGlobalConstant,
  updateLdsOrder,
  getOrgTourInfo,
  saveLDSSuppliers,
  loadLDSSuppliers,
  getFutureOrdersCommission
};
