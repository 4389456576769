import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FinanceTable from "./FinanceTable/FinanceTable";
import FinanceHeader from "./FinanceHeader";
import BalanceWidget from "./FinanceWidgets/BalanceWidget";
import WithdrawalWidget from "./FinanceWidgets/WithdrawalWidget";
import {
  getFinancesByEmail,
  createFinance,
  getFinanceData,
  approveFinanceRequest,
  declineFinanceRequest,
  setFinanceRequestComments,
  getAvailableOrdersCommission,
} from "../../services/fhpService";

import { getUserDetails, isAdminUser } from "../../services/authService";
import { successMessage } from "../../services/swalService";

import "./Finance.css";
import AvailableCommission from "../Agent/SearchAgent/availableCommission/availableCommssion";
import { Button } from "@mui/material";

const Finance = () => {
  const userEmail = getUserDetails()?.email;
  const isAdmin = isAdminUser();

  const [availableCommission, setAvailableCommission] = useState([]);
  const [finances, setFinances] = useState();
  const [possibleProfit, setPossibleProfit] = useState();
  const [withdrawalSum, setWithdrawalSum] = useState(0);
  const [withdrawalEmail, setWithdrawalEmail] = useState(userEmail);
  const [invoiceString, setInvoiceStrBase64] = useState();
  const [validationMsgArr, setValidationMsgArr] = useState([]);
  const [pdfPath, setPdfPath] = useState("");
  const [loading, setLoading] = useState(false);
  const [tableOpen, setTableOpen] = useState(false);
  const [checked, setChecked] = useState([]);

  const getFinancesFromServer = async () => {
    setFinances();
    const agentEmail = JSON.parse(localStorage.getItem("UserInfo")).email;
    let financesData = await getFinancesByEmail({
      email: agentEmail,
    });

    const balanceData = await getFinanceData({
      email: agentEmail,
    });

    setPossibleProfit(balanceData?.data?.possibleProfit);
    setFinances(financesData?.data);
  };

  const getAvailableCommission = async () => {
    // Get agent's email
    let agentEmail = JSON.parse(localStorage.getItem("UserInfo")).email;

    let data = {
      email: agentEmail,
    };
    let ordersData = await getAvailableOrdersCommission(data);
    setAvailableCommission(ordersData?.data);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await getFinancesFromServer();
      await getAvailableCommission();
      setLoading(false);
    };
    fetchData();
  }, []);

  const validateFinanceFields = () => {
    const validationMsg = [];
    if (!withdrawalSum) {
      validationMsg.push("סכום למשיכה לא תקין");
    }
    if (
      withdrawalSum >
        availableCommission.reduce((prev, cur) => {
          return prev + cur.commission;
        }, possibleProfit) &&
      !isAdmin
    ) {
      validationMsg.push("סכום  למשיכה לא יכול להיות גבוה מהיתרה הזמינה ");
    }
    if (!invoiceString && !isAdmin) {
      validationMsg.push("חשבונית לא תקינה");
    }
    return validationMsg;
  };

  const clickAddFinance = async () => {
    setLoading(true);
    const validationMsg = await validateFinanceFields();

    if (validationMsg.length === 0) {
      const system_sum = checked.includes("system") ? possibleProfit : 0;
      const checkedOrders = checked.filter((i) => i !== "system");
      createFinance({
        email: userEmail,
        financeData: {
          checked: checkedOrders,
          system_sum: system_sum,
          sum: withdrawalSum,
          currency: "ILS",
          status: "waiting",
          comments: "",
          invoice: invoiceString,
          email: withdrawalEmail,
        },
      }).then(async (res) => {
        if (res) {
          getFinancesFromServer();
          getAvailableCommission();
          successMessage({
            title: "הבקשה התקבלה בהצלחה",
            message: "",
            confirmButtonText: "אישור",
          });

          setInvoiceStrBase64("");
          setPdfPath("");
          setWithdrawalSum("");
          setChecked([]);
          setLoading(false);
        }
      });
    } else {
      setValidationMsgArr(validationMsg);
      setLoading(false);
    }
  };

  const clickApproveRequest = async ({ email, reqNumber }) => {
    setFinances();

    await approveFinanceRequest({ email, reqNumber });
    getFinancesFromServer();
  };

  const clickDeclineRequest = async ({ email, reqNumber }) => {
    setFinances();

    await declineFinanceRequest({ email, reqNumber });
    getFinancesFromServer();
  };

  const addRequestComments = async ({ email, reqNumber, comments }) => {
    await setFinanceRequestComments({
      email,
      reqNumber,
      comments,
    });
    getFinancesFromServer();
  };

  return (
    <>
      <FinanceHeader />
      {tableOpen ? (
        <>
          <div
            className="center"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "500px",
            }}
          >
            <div className="table" style={{ width: "85%", height: "100%" }}>
              <AvailableCommission
                loading={loading}
                availableCommission={availableCommission}
                possibleProfit={possibleProfit}
                setChecked={(id) => {
                  if (checked.includes(id)) {
                    setChecked(checked.filter((i) => i !== id));
                  } else {
                    setChecked([...checked, id]);
                  }
                }}
                checked={checked}
              />
              <div className="bottom">
                <Button
                  key="go-back"
                  onClick={() => setTableOpen(false)}
                  color="warning"
                >
                  חזור
                </Button>
                <Button
                  key="continue"
                  onClick={() => {
                    setWithdrawalSum(
                      checked.reduce(
                        (acc, curr) =>
                          acc +
                          (curr === "system"
                            ? possibleProfit
                            : availableCommission.find((i) => i.id === curr)
                                ?.commission || 0),
                        0
                      )
                    );
                    setTableOpen(false);
                  }}
                  color="primary"
                >
                  המשך
                </Button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="table-container">
            <div className="finance-widgets-container">
              <BalanceWidget
                onClick={() => setTableOpen(true)}
                loading={loading}
                possibleProfit={availableCommission.reduce(
                  (acc, curr) => acc + curr.commission,
                  possibleProfit
                )}
              ></BalanceWidget>
              <WithdrawalWidget
                loading={loading}
                withdrawalSum={withdrawalSum}
                setWithdrawalSum={setWithdrawalSum}
                setInvoiceStrBase64={setInvoiceStrBase64}
                clickAddFinance={clickAddFinance}
                pdfPath={pdfPath}
                setPdfPath={setPdfPath}
                withdrawalEmail={withdrawalEmail}
                setWithdrawalEmail={setWithdrawalEmail}
                openTable={() => setTableOpen(true)}
              />
            </div>
            <FinanceTable
              finances={finances}
              clickApproveRequest={clickApproveRequest}
              clickDeclineRequest={clickDeclineRequest}
              addRequestComments={addRequestComments}
            ></FinanceTable>
          </div>
          <Modal
            open={validationMsgArr.length > 0}
            onClose={() => setValidationMsgArr([])}
          >
            <Box className="validation-modal">
              לא ניתן ליצור משיכה...
              <Typography>
                <div className="validation-text">
                  {validationMsgArr.map((msg) => {
                    return <div> • {msg} </div>;
                  })}
                </div>
              </Typography>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};

export default Finance;
