import React from "react";
import walletImg from "../../../assets/images/finance/wallet.png";
import "./BalanceWidget.css";
import { Loader } from "../../Loader/Loader";
import { convertPrice } from "../../../services/currencyService";

const BalanceWidget = ({ possibleProfit, loading, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={
        "balance-widget" +
        (typeof possibleProfit === "number" && possibleProfit > 0
          ? " enabled"
          : "")
      }
    >
      <img src={walletImg} alt="wallet logo" className="wallet-logo" />
      <div className="balance-text">יתרה זמינה למשיכה</div>
      <div className="balance-number">
        {typeof possibleProfit === "number" && !loading ? (
          convertPrice(possibleProfit)
        ) : (
          <Loader size="small" />
        )}
      </div>
    </div>
  );
};

export default BalanceWidget;
