import React from "react";
import { useSelectedResult } from "../../../contexts/SelectedResultContext";
import { useParams } from "react-router-dom";
import { getLDSOrderById, getLdsOrder } from "../../../services/fhpService";
import OrganizedTourProfile from "./OrganizedTourProfile";
import { countAdultsAndKids } from "../../../utils/LDSHelpers";
import { useSearch } from "../../../contexts/SearchContext";
import { useAppContext } from "../../../contexts/AppContext";
import { Helmet } from "react-helmet";

const SavedToursProfile = () => {
  const { id } = useParams();
  const {
    setOurSavedLDSOrder,
    ourSavedLDSOrder,
    setSelectedResult,
    updateHandleFeeData,
    handleFeeData,
    setAgentData,
    setRoomsAndPaxInfo,
    setOurPrice,
  } = useSelectedResult();
  const [error, setError] = React.useState(false);

  const { calculateLDSDealFare } = useSearch();
  const { currencyRate } = useAppContext();

  const getLdsData = (
    dealid,
    dep_date,
    ret_date,
    passengersInfo,
    tour = "false"
  ) => {
    const { totalAdults, totalKids, totalInfant } =
      countAdultsAndKids(passengersInfo);

    getLDSOrderById({
      ldsDealId: dealid,
      depDate: dep_date,
      retDate: ret_date,
      numOfAdults: totalAdults,
      numOfChildren: totalKids,
      numOfInfants: totalInfant,
      tour: tour,
    }).then((res) => {
      const status = res.data?.opStatus?.status;
      if (status === "NO_RESULTS") {
        console.log("ERROR: No results");
        setError(true);
        return;
      }
      const deal = res.data.deal;
      const ourPrice = calculateLDSDealFare(deal);
      if (!ourPrice) return;
      deal.ourPrice = ourPrice;
      setSelectedResult(deal);
    });
  };

  React.useEffect(() => {
    if (!id || !currencyRate) {
      return;
    }
    getLdsOrder({ id })
      .then((res) => {
        setOurSavedLDSOrder(res.data);
        setAgentData(res.data.agent);
        const { passengersinfo, dealid, dep_date, ret_date, ourprice } =
          res.data;
        console.log(passengersinfo);
        getLdsData(dealid, dep_date, ret_date, passengersinfo, "true");
        setRoomsAndPaxInfo(passengersinfo);
        if (res.data.ourprice?.handleFeeData) {
          const handleFeeData = res.data.ourprice.handleFeeData;
          if (!handleFeeData.type || !handleFeeData.value) {
            return;
          }
          updateHandleFeeData(handleFeeData.type, handleFeeData.value);
        }
        ourprice.handleFeeData = handleFeeData;
        setOurPrice(ourprice);
      })
      .catch((err) => {
        console.log("ERROR: " + err);
        setError(true);
      });
  }, [id, currencyRate]);

  if (error || !id) {
    return (
      <>
        <Helmet>
          <title>הצעה מספר {ourSavedLDSOrder?.dealid || ""}</title>
          <meta
            name="description"
            content={"לקוח יקר, מצורפת הצעת מחיר לעיונך ואישורך"}
          />
          <meta
            property="og:title"
            content={`הצעת מחיר מספר ${ourSavedLDSOrder?.dealid || ""}`}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:description"
            content={"לקוח יקר, מצורפת הצעת מחיר לעיונך ואישורך"}
          />
          <meta property="og:url" content={window.location.href} />
        </Helmet>
        <div style={{ color: "red", fontSize: 30 }}>
          אירעה שגיאה, אנה נסה שנית מאוחר יותר
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>הצעה מספר {ourSavedLDSOrder?.dealid || ""}</title>
        <meta
          name="description"
          content={"לקוח יקר, מצורפת הצעת מחיר לעיונך ואישורך"}
        />
        <meta
          property="og:title"
          content={`הצעת מחיר מספר ${ourSavedLDSOrder?.dealid || ""}`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content={"לקוח יקר, מצורפת הצעת מחיר לעיונך ואישורך"}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <OrganizedTourProfile orderSaved={true} ourLdsId={id} />
    </>
  );
};

export default SavedToursProfile;
