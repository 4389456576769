import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

// Languages
import { useTranslation } from "react-i18next";

// CSS
import "./futurecommission.css";

// Services
import { convertPrice } from "../../../../services/currencyService";

// Components
import ClientsInfo from "../../OrderSummery/ClientsInfo/clientsinfo";
import FlightsInfo from "../../OrderSummery/FlightsInfo/flightsinfo";
import HotelInfo from "../../OrderSummery/HotelInfo/hotelinfo";

// Icons
import ZoomOutIcon from "../../../../assets/images/ordersummery/zoom-out.png";
import { getOrderPrice } from "../../../../utils/OrderService";
import { Loader } from "../../../Loader/Loader";

const FutureCommssion = ({ loading, futureCommission }) => {
  // Navigator
  const navigate = useNavigate();

  // Transelator
  const { t } = useTranslation();

  // States
  const [isShowOrder, setIsShowOrder] = useState(false);
  const [showOrderDetails, setShowOrderDetails] = useState(null);

  // Refs
  const excludedOrderDisplayerRef = useRef(null);

  // --- UseEffects

  // Add eventer listeners that exits display shower
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isShowOrder &&
        !excludedOrderDisplayerRef.current.contains(event.target) &&
        event.target.id !== "button-show-order-summery"
      ) {
        setIsShowOrder(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isShowOrder]);

  // --- Functions

  const showOrder = (orderinfo) => {
    // Show the order displayer
    setIsShowOrder(true);

    // Save the order
    setShowOrderDetails(orderinfo);
  };

  const quitOrderShower = () => {
    setIsShowOrder(false);
  };

  // Handle payment
  const handlePayment = (showOrderDetails) => {
    let orderID = showOrderDetails.id;

    // Navigate to payment page
    navigate(`/p/dashboard/payment/${orderID}`);
  };

  function renderItems() {
    if (futureCommission.length) {
      return futureCommission?.map((orderinfo, index) => (
        <tr
          key={index}
          className={`futureCommissions-order ${
            orderinfo.paid
              ? "futureCommissions-order-paid"
              : "futureCommissions-order-not-paid"
          } ${
            document.documentElement.dir === "rtl"
              ? "direction-rtl"
              : "direction-ltr"
          }`}
        >
          <td className="futureCommissions-order-item futureCommissions-order-item3">
            {orderinfo.client_name}
          </td>
          <td className="futureCommissions-order-item futureCommissions-order-item3">
            {orderinfo.type}
          </td>
          <td className="futureCommissions-order-item futureCommissions-order-item3">
            {orderinfo.checkout}
          </td>
          <td className="futureCommissions-order-item futureCommissions-order-item3">
            {orderinfo.commission}₪
          </td>
        </tr>
      ));
    }
    return <div className="no-data">אין עמלות עתידיות </div>;
  }

  return (
    <>
      <div className="futureCommissions-wrapper">
        {/* Title */}
        <label className="futureCommissions-title">
          {t("upcomingorders.futurecommission")}
        </label>

        {/* Order Wrapper */}
        <div
          className={`futureCommissions-order-wrapper ${
            document.documentElement.dir === "ltr"
              ? "direction-rtl"
              : "direction-ltr"
          }`}
        >
          {loading || !futureCommission ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Loader />
            </div>
          ) : futureCommission.length === 0 ? (
            <div className="no-data">אין עמלות עתידיות </div>
          ) : (
            <table>
              <thead>
                <tr
                  className={`futureCommissions-header-wrapper ${
                    document.documentElement.dir === "rtl"
                      ? "direction-rtl"
                      : "direction-ltr"
                  }`}
                >
                  <th className="futureCommissions-header-item">
                    {t("upcomingorders.client-name")}
                  </th>
                  <th className="futureCommissions-header-item">
                    {t("upcomingorders.type")}
                  </th>
                  <th className="futureCommissions-header-item">
                    {t("upcomingorders.money-transfer")}
                  </th>
                  <th className="futureCommissions-header-item">
                    {t("upcomingorders.amount")}
                  </th>
                </tr>
              </thead>
              <tbody className="futureCommissions-orders-wrapper">
                {renderItems()}
              </tbody>
            </table>
          )}
        </div>

        {/* Order Displayer */}
        {isShowOrder && (
          <div className="futureCommissions-order-displayer-wrapper">
            <div
              className="futureCommissions-order-displayer lg:tw-w-[30vw]"
              ref={excludedOrderDisplayerRef}
            >
              {/* Header */}
              <div className="futureCommissions-header">
                <label className="orderSummery-header-item orderSummery-header-item-selected">
                  {t("ordersummery.ordersummery")}
                </label>
              </div>

              {/* Summery Content */}
              <div className="futureCommissions-content">
                <ClientsInfo
                  clientName={showOrderDetails.fullname}
                  clientPhone={showOrderDetails.cell}
                  clientEmail={showOrderDetails.email}
                ></ClientsInfo>
                <FlightsInfo />
                <HotelInfo hotelInfo={showOrderDetails} />
              </div>

              {/* Pricing */}
              <div className="footer-wrapper">
                {/* Order Summery Price */}
                <div className="orderSummery-price-wrapper">
                  <label>{t("ordersummery.ordersummery")}:</label>
                  <label id="price-show-order-summery">
                    {convertPrice(getOrderPrice(showOrderDetails))}
                  </label>
                </div>

                {/* Order Summery button */}
                {!showOrderDetails.paid ? (
                  <div className="orderSummery-payment-btn-wrapper">
                    <button
                      onClick={() => handlePayment(showOrderDetails)}
                      className="orderSummery-payment-btn"
                    >
                      {t("ordersummery.payment")}
                    </button>
                  </div>
                ) : (
                  <div className="futureCommissions-paid-wrapper">
                    <label className="futureCommissions-paid-title">
                      {t("upcomingorders.paid")}
                    </label>
                  </div>
                )}
              </div>

              {/* Close Display Shower */}
              <div
                className={
                  document.documentElement.dir === "rtl"
                    ? "futureCommissions-wrapper-icon-he"
                    : "futureCommissions-wrapper-icon"
                }
              >
                <img
                  onClick={quitOrderShower}
                  className="orderSummery-icon"
                  src={ZoomOutIcon}
                  alt="Enhance order summery"
                ></img>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FutureCommssion;
