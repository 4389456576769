import React from "react";
import { convertPrice } from "../../../services/currencyService";
import { useSelectedResult } from "../../../contexts/SelectedResultContext";

const TotalPayment = ({ totalPrice }) => {
  const { ilsToUsd } = useSelectedResult();
  return (
    <span>
      {convertPrice(parseFloat(totalPrice))} ({ilsToUsd(parseFloat(totalPrice))}
      $)
    </span>
  );
};

export default TotalPayment;
