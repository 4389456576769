import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// React Icons
import { GiGreekTemple } from "react-icons/gi";
import { AiOutlineCheck } from "react-icons/ai";

// Loading
import ReactLoading from "react-loading";

// Languages
import { useTranslation } from "react-i18next";

// CSS
import "./hotelprofile.css";

// Contexts

// Components
import StarRating from "../../../Components/StarRating/starrating";
import ImageGallery from "./Components/ImageGallery/imagegallery";
import LocationMap from "./Components/LocationMap/locationmap";
import BottomNavBarClient from "../../../Components/Client/BottomNavBarClient/bottomnavbarclient";
import RoomPlacer from "./Components/RoomPlacer/roomplacer";
import CheckInOutDisplayer from "./Components/CheckInOutDisplyer/checkinoutdisplayer";

// Services
import {
  getHotelsProfileData,
  getHotelsDescription,
  getHotelsImage,
  checkInInfo,
  getFacilities,
} from "../../../services/fhpService";
import { convertPrice } from "../../../services/currencyService";
import { parsePriceToShow } from "../../../utils/OrderService";
import HotelDescription from "./HotelDescription";
import { Loader } from "../../../Components/Loader/Loader";
import Timer from "../../Timer/Timer";
import Back from "../../../Components/Back/Back";
import { useAppContext } from "../../../contexts/AppContext";

const HotelProfile = () => {
  // Access the hotelid parameter from the route
  const { hotelid } = useParams();
  const { calcBaseCommission, isGlobalConstantLoaded } = useAppContext();

  // States
  const [hotelInfo, setHotelInfo] = useState("");
  const [totalRooms, setTotalRooms] = useState("");
  const [dates, setDates] = useState("__/__/____ - __/__/____");
  const [numberOfNights, setNumberOfNights] = useState("");
  const [rooms, setRooms] = useState("");
  const [currentSearch, setCurrentSearch] = useState(null);
  const [hotelDescription, setHotelDescription] = useState(null);
  const [hotelMainImage, setHotelMainImage] = useState();
  const [CheckInOutInfo, setCheckInOutInfo] = useState(null);
  const [faciliteisInfo, setFaciliteisInfo] = useState([]);

  const [mapTrigger, setMapTrigger] = useState();

  // Contexts

  // Translator
  const { t } = useTranslation();

  // useEffects

  // Configure hotel profile's data
  useEffect(() => {
    const setUpHotelProfile = async () => {
      const getHotelInfo = async (searchPhrase) => {
        // Get hotel information

        const res = await getHotelsProfileData(searchPhrase);

        // Transfer to hotel profile
        if (res?.status === 200) {
          return res.data;
        } else {
          return null;
        }

        setMapTrigger(true);
      };

      let searchPhrase = JSON.parse(
        localStorage.getItem("currentSearchPhrase")
      );

      setCurrentSearch(searchPhrase);

      // Get hotel info
      let hotelData = await getHotelInfo(searchPhrase);

      if (hotelData) {
        // Save hotel profile information
        // hotelData.StartingPrice = calcBaseCommission(StartingPrice);
        hotelData["StartingPrice"] = calcBaseCommission(
          hotelData["StartingPrice"]
        );
        setHotelInfo(hotelData);
        // Set up and rearrange the data
        extractDataFromSearch(hotelData);

        // Change the title when the component mounts
        document.title = hotelData.HotelName + " - Hotels River";
      }

      const roomBoxes = hotelData?.boxes?.map((room, index) => {
        room.packages = room.packages.map((pkg) => {
          pkg.ourPrice = calcBaseCommission(pkg.finalPrice);
          return pkg;
        });
        return { ...room, id: index + 1 };
      });

      setRooms(roomBoxes ? roomBoxes : []);
    };

    if (localStorage.getItem("currentSearchPhrase")) {
      setUpHotelProfile();
    }
  }, [isGlobalConstantLoaded]);

  // Get describtion and main image
  useEffect(() => {
    const getDescriptionFromApi = async () => {
      // const data = localStorage.getItem('currentSearchPhrase');
      // const searchPhrase = data ? JSON.parse(data) : null;

      // Get hotel description
      const data = {
        hotelID: parseInt(hotelid),
      };

      getHotelsDescription(data)
        .then((res) => {
          if (res && res.data && res.data.status !== "fail") {
            setHotelDescription(res.data);
          } else {
            // setHotelDescription([])
          }
        })
        .catch((err) => {
          // setHotelDescription([])
        });

      const resImage = await getHotelsImage(data);

      if (resImage && resImage.data) {
        setHotelMainImage(resImage.data.image);
      }

      // Trigger second map to appear
      setMapTrigger(true);
    };

    getDescriptionFromApi();
  }, []);

  // Get check In Out info
  useEffect(() => {
    const getCheckInInfo = async () => {
      let searchPhrase = JSON.parse(
        localStorage.getItem("currentSearchPhrase")
      );

      const data = {
        hotelID: hotelid,
        session: searchPhrase.session,
        packageID: hotelInfo.Result[0].PackageId,
      };

      const res = await checkInInfo(data);

      if (res.data.status === "nodata") {
        const data = {
          checkIn: t("hotelprofile.scheduallater"),
          checkInText: t("hotelprofile.getroomsatwithhotel"),
          checkOut: t("hotelprofile.scheduallater"),
          checkOutText: t("hotelprofile.getroomsuntilwithhotel"),
        };

        setCheckInOutInfo(data);
      }

      if (res.data.status) {
        const data = {
          checkIn: "",
          checkInText: t("hotelprofile.getroomsat"),
          checkOut: "",
          checkOutText: t("hotelprofile.returnroomsuntil"),
        };
      }
      // setCheckInOutInfo
    };

    if (
      hotelInfo &&
      hotelInfo.Result?.length > 0 &&
      localStorage.getItem("currentSearchPhrase")
    ) {
      getCheckInInfo();
    }
  }, [hotelInfo]);

  // Get facilities data
  useEffect(() => {
    const getFacilitiesFromAPI = async () => {
      const data = {
        hotelID: parseInt(hotelid),
      };

      const res = await getFacilities(data);

      if (res && res.data && res.data.status === "ok") {
        setFaciliteisInfo(res.data.facilities);
      }
    };

    if (hotelid) {
      getFacilitiesFromAPI();
    }
  }, [hotelid]);

  // --- Functions
  const extractDataFromSearch = (data) => {
    // Get data
    setTotalRooms(data.Rooms ? data.Rooms.length : 0);
    setDates(data.CheckIn + " - " + data.CheckOut);
    setNumberOfNights(data.Nights);
  };

  // Scroll to rooms
  const showToRooms = () => {
    const roomsInfoElement = document.getElementById("Rooms-Information");
    if (roomsInfoElement) {
      roomsInfoElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Show neighberhood button
  const showNeiberhood = () => {
    const map2 = document.getElementById("map-2");

    if (map2) {
      map2.querySelector(".gm-control-active.gm-fullscreen-control").click();
    }
  };

  return (
    <>
      <div className="hotelProfile-component">
        {/* <Timer /> */}
        {hotelInfo && hotelInfo.HotelName ? (
          <div className="hotelProfile-wrapper tw-p-5 lg:tw-px-[15%]">
            <div className="hotelProfile-info-wrapper">
              {/* Title */}
              <div className="hotelProfile-header">
                <div className="hotelProfile-header-desc">
                  <StarRating rating={hotelInfo.Rating}></StarRating>

                  <label className="hotelProfile-header-desc-title">
                    {hotelInfo.HotelName}
                  </label>

                  <label className="hotelProfile-header-desc-sub-title">
                    {hotelInfo.Address}
                  </label>
                </div>

                <div className="hotelProfile-header-pricing">
                  <label
                    className={`hotelProfile-header-pricing-title ${
                      document.documentElement.dir === "rtl"
                        ? "direction-rtl"
                        : "direction-ltr"
                    }  `}
                  >
                    <span className="hotelProfile-header-pricing-sub-title">
                      {" "}
                      {t("hotelprofile.startsat")}{" "}
                    </span>
                    <span
                      id="price-start-hotel-profile"
                      className="hotelProfile-header-pricing-price"
                    >
                      {convertPrice(
                        parsePriceToShow(
                          hotelInfo.StartingPrice
                            .priceWithCommissionAndCreditCardFee
                        )
                      )}
                    </span>
                  </label>

                  <button
                    onClick={showToRooms}
                    className="hotelProfile-header-pricing-btn"
                  >
                    {t("hotelprofile.showrooms")}
                  </button>
                </div>
              </div>

              {/* Images | Google Map | Facilities | Switch Search */}
              <div className="hotelProfile-content-wrapper">
                {/* Images Gallery*/}
                <div className="hotelProfile-content-gallery-wrapper">
                  <ImageGallery hotelid={hotelid} />
                </div>

                {/* Amenetis / Google Map */}
                <div className="hotelProfile-content-amenatis-googlemaps-wrapper">
                  {/* Amenatis wrapper */}
                  <div className="hotelProfile-content-amenatis-wrapper">
                    <label>השירותים העיקריים במלון</label>

                    {/* Amenatis */}
                    <div className="hotelProfile-content-all-amenatis">
                      {faciliteisInfo.map((facility, index) => (
                        <div
                          key={index}
                          className="hotelProfile-content-all-amenatis-check-wrapper"
                        >
                          <label>{facility.facilityname}</label>
                          <AiOutlineCheck className="hotelProfile-content-all-amenatis-check" />
                        </div>
                      ))}
                      {/* {faciliteisInfo.slice(0, 10).map((facility, index) => (
                                                <label key={index}>{facility.facilityname}</label>
                                            ))} */}
                    </div>
                  </div>

                  {/* Google Maps Wrapper */}
                  {/* <div className="hotelProfile-content-googlemaps-wrapper">
                    {hotelInfo.Geolocation && (
                      <LocationMap
                        lat={hotelInfo.Geolocation.lat}
                        lng={hotelInfo.Geolocation.long}
                        mapId="map-1"
                      />
                    )}
                  </div> */}
                </div>

                {/* Search Info wrapper*/}
                <div className="hotelProfile-content-search-info-wrapper">
                  <label className="hotelProfile-content-search-info-title">
                    <b>{t("hotelprofile.searcheddates")}:</b> {numberOfNights}{" "}
                    {t("hotelprofile.nights")},{" "}
                    <span className="hotelProfile-content-search-info-title-date">
                      {dates}
                    </span>
                    , {t("hotelprofile.for")} {totalRooms}{" "}
                    {t("hotelprofile.rooms")}.
                  </label>
                  {/* <button className='hotelProfile-content-search-info-btn'>{t('hotelprofile.editsearch')}</button> */}
                </div>
              </div>
            </div>

            {/* Rooms / Filter */}
            <div id="Rooms-Information" className="hotelProfile-rooms-wrapper">
              <RoomPlacer
                rooms={rooms}
                currentSearch={currentSearch}
                hotelInfo={hotelInfo}
                checkInOutInfo={CheckInOutInfo}
              />
            </div>
            {/* 
            <div className="hotelProfile-describtion">
              <div className="hotelProfile-describtion-content">
                <div className="hotelProfile-describtion-img-wrapper">
                  {hotelMainImage && (
                    <img
                      className="hotelProfile-describtion-img"
                      src={hotelMainImage}
                    ></img>
                  )}
                  <div className="hotelProfile-describtion-img-title-wrapper">
                    <label className="hotelProfile-describtion-img-title">
                      {hotelInfo.HotelName}
                    </label>
                  </div>
                </div>

                <HotelDescription description={hotelDescription} />
              </div>

              <div className="hotelProfile-describtion-maps">
                <div>
                  <div className="hotelProfile-checkInOutDisplayer-wrapper">
                    {CheckInOutInfo && (
                      <div className="hotelProfile-checkInOutDisplayer">
                        <CheckInOutDisplayer
                          time={CheckInOutInfo.checkIn}
                          title={t("hotelprofile.checkin")}
                          subText={CheckInOutInfo.checkInText}
                        />
                        <CheckInOutDisplayer
                          time={CheckInOutInfo.checkOut}
                          title={t("hotelprofile.checkout")}
                          subText={CheckInOutInfo.checkOutText}
                        />
                      </div>
                    )}
                  </div>

                  <div>
                    <div className="hotelProfile-content-googlemaps-desc">
                      {hotelInfo.Geolocation && (
                        <LocationMap
                          lat={hotelInfo.Geolocation.lat}
                          lng={hotelInfo.Geolocation.long}
                          trigger={mapTrigger}
                          mapId="map-2"
                        />
                      )}
                    </div>

                    <button
                      onClick={showNeiberhood}
                      className="hotelProfile-showArround"
                    >
                      גלו את הסביבה
                    </button>
                  </div>

                  <div className="hotelProfile-describtion-attractions">
                    <label className="hotelProfile-describtion-attractions-title">
                      בסביבה הקרובה
                    </label>

                    {hotelDescription &&
                      hotelDescription.attractions.length > 0 && (
                        <div>
                          <label className="hotelProfile-describtion-attractions-sub-title">
                            ציוני דרך
                          </label>

                          <ul className="hotelProfile-describtion-attractions-content-wrapper">
                            {hotelDescription.attractions.map((row, index) => (
                              <li
                                key={index}
                                className="hotelProfile-describtion-attractions-content-row"
                              >
                                {" "}
                                <GiGreekTemple />
                                {row}{" "}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
            </div>
             */}
          </div>
        ) : (
          <div className="hotelProfile-loading-screen-wrapper">
            <div className="loading-screen">
              <Loader size="large" />
            </div>
          </div>
        )}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Back />
        </div>
        {/* Bottom NavBar */}
        <BottomNavBarClient />
      </div>
    </>
  );
};

export default HotelProfile;
