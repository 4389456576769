import React from "react";
import { useSearch } from "../../../../contexts/SearchContext";
import DestinationInput from "../SubComponents/DestinationInput/DestinationInput";
import DatesInput from "../SubComponents/DatesInput/DatesInput";
import PeopleCounterInput from "../SubComponents/PeopleCounterInput/PeopleCounterInput";
import "./organizedTourSearchBar.css";
import {
  getAllDepartureDates,
  getAvailableMonthOfDeparture,
  getReturnDatesForDeparture,
} from "../../../../contexts/SearchContextHelpers";
import { Loader } from "../../../Loader/Loader";
import { ConfigProvider, DatePicker, Select, Tooltip } from "antd";
import he_IL from "antd/locale/he_IL";
import dayjs from "dayjs";
import "dayjs/locale/he";
import { LDS_CATEGORIES } from "../../../../assets/Data/LdsAirportsAutocomplete";

dayjs.locale(
  "he",
  {
    months: [
      "ינואר",
      "פברואר",
      "מרץ",
      "אפריל",
      "מאי",
      "יוני",
      "יולי",
      "אוגוסט",
      "ספטמבר",
      "אוקטובר",
      "נובמבר",
      "דצמבר",
    ],
  },
  true
);

const locale = {
  ...he_IL.DatePicker,
  lang: {
    ...he_IL.DatePicker.lang,
    monthFormat: "MM",
  },
};

const OrganizedTourSearchBar = () => {
  const {
    setTourMonth,
    setCategories,
    roomInfo,
    setRoomInfo,
    location,
    setLocation,
    ldsDates,
    datesError,
    loadingDates,
  } = useSearch();

  function renderInput() {
    const defaultProps = {
      width: 120,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };
    if (datesError) {
      return <div style={{ ...defaultProps }}>{datesError}</div>;
    }
    if ((!location || location.length === 0) && !ldsDates) {
      return <div style={{ ...defaultProps }}>יש לבחור יעד</div>;
    }
    if (loadingDates) {
      return (
        <div
          style={{
            ...defaultProps,
          }}
        >
          <Loader size="small" />
        </div>
      );
    }
    const availableMonths = getAvailableMonthOfDeparture(ldsDates);

    const disabledDate = (current) => {
      // Can not select days before today and today
      return (
        current && availableMonths.indexOf(current.format("MM/YYYY")) === -1
      );
    };

    return (
      <Tooltip
        title={
          location == ""
            ? "בחר יעד"
            : availableMonths.length === 0
              ? "אין תאריכים זמינים"
              : undefined
        }
      >
        <ConfigProvider locale={he_IL}>
          <DatePicker.MonthPicker
            format="MM/YYYY"
            defaultValue={undefined}
            disabledDate={disabledDate}
            disabled={location === "" || availableMonths.length === 0}
            onChange={(date, dateString) => {
              setTourMonth(dateString);
            }}
            locale={locale}
          />
        </ConfigProvider>
      </Tooltip>
    );
  }

  return (
    <>
      <DestinationInput
        location={location}
        setLocation={setLocation}
        isLdsInput
        isOrganizedTour={true}
      />
      <Select mode="multiple" allowClear placeholder="סוג"
        options={[
          ...LDS_CATEGORIES.map((category) => ({
            value: category,
            label: category,
          })),
        ]}
        onChange={(value) => {
          setCategories(value);
        }}
        maxTagCount="responsive"
        // dropdownRender={(menu) => (
        //   <>
        //     {menu}
        //     <Divider />
        //     <Button
        //       type="primary"
        //       onClick={() => {
        //         setCategories(LDS_CATEGORIES);
        //       }}
        //       style={{ width: "100%", textAlign: "right" }}
        //     >
        //       בחר הכל
        //     </Button>
        //   </>
        // )}
        style={{ width: 200, height: "57px" }}
      />
      {renderInput()}

      <PeopleCounterInput
        roomInfo={roomInfo}
        setRoomInfo={setRoomInfo}
        isTour={true}
      />
      {/*<div>שים לב, החיפוש יופעל עם טווח של -+2 ימים</div>*/}
    </>
  );
};

export default OrganizedTourSearchBar;
