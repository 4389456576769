import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  PriceFilter,
  TextFilter,
  CheckboxFilter,
  StarRatingFilter,
} from "../Filters";
import { useSearch } from "../../../../../contexts/SearchContext";

import "./index.scss";
import TextFilterNumber from "../Filters/TextFilterNumber";

const LOW_VALUE = 1;
const HIGH_VALUE = 1000000;

const OrganizedTourFilter = ({ setSearchResultsFiltered }) => {
  const { t } = useTranslation();
  const { searchResults } = useSearch();

  const ALL_RESULTS_RANGE_PRICE = useMemo(() => {
    const calculateRangePrice = () => {
      if (!searchResults || !searchResults.length) {
        return {
          high: HIGH_VALUE,
          low: LOW_VALUE,
        };
      }

      const prices = searchResults.map((item) => {
        return parseInt(
          item?.fullDeal?.dealFare?.totalFare || item?.dealFare?.totalFare
        );
      });

      return {
        high: Math.max(...prices),
        low: Math.min(...prices),
      };
    };

    return calculateRangePrice();
  }, [searchResults]);

  const [hotelName, setHotelName] = useState();
  const [nights, setNights] = useState(null);
  const [rangePrice, setRangePrice] = useState();
  const [priceSort, setPriceSort] = useState();
  const [autoApprove, setAutoApprove] = useState();
  const [needApprove, setNeedApprove] = useState();
  const [starRating, setStarRating] = useState([]);
  const [starRatingSortHighToLow, setStarRatingSortHighToLow] = useState();
  const [boardType, setBoardType] = useState([]);

  const handleStarRatingClick = (number) => {
    if (!number) {
      setStarRating([]);
    } else {
      if (starRating.includes(number)) {
        setStarRating((prevNumbers) =>
          prevNumbers.filter((num) => num !== number)
        );
      } else {
        setStarRating((prevNumbers) => [...prevNumbers, number]);
      }
    }
  };

  const avilibilityFilters = [
    {
      value: autoApprove,
      onChange: setAutoApprove,
      label: "באישור מיידי",
    },
    {
      value: needApprove,
      onChange: setNeedApprove,
      label: "נדרש אישור של המלון",
    },
  ];

  const addBoardType = (type) => {
    if (!type) {
      setBoardType([]);
    } else {
      if (boardType.includes(type)) {
        setBoardType((prevState) => prevState.filter((curr) => curr !== type));
      } else {
        setBoardType((prevState) => [...prevState, type]);
      }
    }
  };
  const boardTypeFilters = [
    {
      value: boardType.includes("BB"),
      onChange: (value) => (value ? addBoardType("BB") : setBoardType([])),
      label: t("boards.BB"),
    },
    {
      value: boardType.includes("HB"),
      onChange: (value) => (value ? addBoardType("BBHB") : setBoardType([])),
      label: t("boards.HB"),
    },
    {
      value: boardType.includes("FB"),
      onChange: (value) => (value ? addBoardType("FB") : setBoardType([])),
      label: t("boards.FB"),
    },
    {
      value: boardType.includes("AI"),
      onChange: (value) => (value ? addBoardType("AI") : setBoardType([])),
      label: t("boards.AI"),
    },
    {
      value: boardType.includes("RO"),
      onChange: (value) => (value ? addBoardType("RO") : setBoardType([])),
      label: t("boards.RO"),
    },
  ];

  // const starRatingFilters = [
  //   {
  //     value: starRatingSortHighToLow,
  //     onChange: setStarRatingSortHighToLow,
  //     label: "מהגבוה לנמוך",
  //   },
  // ];

  const resetFilters = () => {
    setNights(null);
    setHotelName();
    setRangePrice();
    setPriceSort();
    setAutoApprove();
    setNeedApprove();
    setStarRating([]);
    setStarRatingSortHighToLow();
    setBoardType([]);
  };

  const applyFilters = () => {
    let updatedResults = searchResults;

    if (hotelName) {
      updatedResults = updatedResults?.filter((item) => {
        const itemName = item?.fullDeal?.hotel?.hotelName;
        return itemName
          ? itemName.toLowerCase().includes(hotelName.toLowerCase())
          : false;
      });
    }

    if (nights) {
      console.log(nights);
      updatedResults = updatedResults?.filter((item) => {
        const itemNights = Number(item?.tour?.nights);
        return itemNights ? itemNights === Number(nights) : false;
      });
    }

    if (rangePrice) {
      updatedResults = updatedResults?.filter((item) => {
        const itemPrice = parseInt(
          item?.fullDeal?.dealFare?.totalFare || item?.dealFare?.totalFare
        );
        return itemPrice
          ? rangePrice.high >= itemPrice && rangePrice.low <= itemPrice
          : false;
      });
    }

    if (typeof priceSort == "boolean") {
      if (priceSort) {
        updatedResults = updatedResults.sort(
          (a, b) =>
            parseInt(b.fullDeal?.dealFare.totalFare || b.dealFare.totalFare) -
            parseInt(a.fullDeal?.dealFare.totalFare || a.dealFare.totalFare)
        );
      } else {
        updatedResults = updatedResults.sort(
          (a, b) =>
            parseInt(a.fullDeal?.dealFare.totalFare || a.dealFare.totalFare) -
            parseInt(b.fullDeal?.dealFare.totalFare || b.dealFare.totalFare)
        );
      }
    }

    if (autoApprove) {
      updatedResults = updatedResults?.filter((item) => {
        return item?.fullDeal?.hotelAllocation === "true";
      });
    }
    if (needApprove) {
      updatedResults = updatedResults?.filter((item) => {
        return item?.fullDeal?.hotelAllocation !== "true";
      });
    }

    if (starRating && starRating.length) {
      updatedResults = updatedResults?.filter((item) => {
        const itemRating = item?.fullDeal?.hotel?.category?.replace(/\D/g, "");
        return itemRating ? starRating.includes(parseInt(itemRating)) : false;
      });
    }

    if (boardType && boardType.length) {
      updatedResults = updatedResults?.filter((item) => {
        const itemBoard = item?.fullDeal?.hotel?.board;
        return itemBoard ? boardType.includes(itemBoard) : false;
      });
    }

    // if (starRatingSortHighToLow) {
    //   console.log("starRatingSortHighToLow");
    //   updatedResults = updatedResults.sort((a, b) => {
    //     const categoryA = parseInt(
    //       a?.fullDeal?.hotel?.category?.replace(/\D/g, "")
    //     );
    //     const categoryB = parseInt(
    //       b?.fullDeal?.hotel?.category?.replace(/\D/g, "")
    //     );

    //     if (isNaN(categoryA)) {
    //       return isNaN(categoryB) ? 0 : 1;
    //     }

    //     if (isNaN(categoryB)) {
    //       return -1;
    //     }

    //     return categoryA - categoryB;
    //   });
    // }

    setSearchResultsFiltered(updatedResults);
  };

  useEffect(() => {
    applyFilters();
  }, [
    hotelName,
    rangePrice,
    priceSort,
    autoApprove,
    needApprove,
    starRating,
    starRatingSortHighToLow,
    boardType,
    nights,
  ]);

  return (
    <div className="filters-sidebar">
      <label className="package-filters-title">
        {t("filters.filterresults")}
      </label>
      <div className="filters-wrapper">
        <TextFilterNumber
          filterName={"כמות לילות"}
          placeHolder={"כמות לילות"}
          onFilterChange={setNights}
          value={nights}
          searchResults={searchResults}
        />
        <PriceFilter
          onFilterChange={(newValue) =>
            setRangePrice(
              newValue
                ? {
                    low: newValue[0],
                    high: newValue[1],
                  }
                : ALL_RESULTS_RANGE_PRICE
            )
          }
          filterName="מחיר חבילה"
          placeHolder="טווח מחירים"
          lowValue={rangePrice?.low || ALL_RESULTS_RANGE_PRICE?.low}
          highValue={rangePrice?.high || ALL_RESULTS_RANGE_PRICE?.high}
          resultsRangePrice={ALL_RESULTS_RANGE_PRICE}
          curreny="$"
          setPriceSort={setPriceSort}
          priceSort={priceSort}
        />
        {/* <TextFilter
          filterName={"שם המלון"}
          placeHolder={"שם המלון"}
          onFilterChange={setHotelName}
          value={hotelName}
          searchResults={searchResults}
        />
        <TextFilterNumber
          filterName={"כמות לילות"}
          placeHolder={"כמות לילות"}
          onFilterChange={setNights}
          value={nights}
          searchResults={searchResults}
        />
        <PriceFilter
          onFilterChange={(newValue) =>
            setRangePrice(
              newValue
                ? {
                    low: newValue[0],
                    high: newValue[1],
                  }
                : ALL_RESULTS_RANGE_PRICE
            )
          }
          filterName="מחיר חבילה"
          placeHolder="טווח מחירים"
          lowValue={rangePrice?.low || ALL_RESULTS_RANGE_PRICE?.low}
          highValue={rangePrice?.high || ALL_RESULTS_RANGE_PRICE?.high}
          resultsRangePrice={ALL_RESULTS_RANGE_PRICE}
          curreny="$"
          setPriceSort={setPriceSort}
          priceSort={priceSort}
        />
        <StarRatingFilter
          onFilterChange={(value) => {
            handleStarRatingClick(value);
          }}
          selectedValue={starRating}
          filterName="כוכבים"
          // starRatingFilters={starRatingFilters}
        />
        <CheckboxFilter filterName="סוג אירוח" checkboxes={boardTypeFilters} />
        <CheckboxFilter
          filterName="זמינות"
          checkboxes={avilibilityFilters}
          expand={true}
        /> */}
      </div>
      <div className="filters-reset-btn-wrapper">
        <button type="button" className="reset-btn" onClick={resetFilters}>
          {t("filters.reset")}
        </button>
      </div>
    </div>
  );
};

export default OrganizedTourFilter;
