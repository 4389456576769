import React from "react";
// import { useTranslation } from "react-i18next";
// import CreditCardDetails from "../../Agent/PaymentAgent/SplitPayment/subComponent/CreditCardDetails/creditcarddetails";
import CardPayment from "../CardPayment/CardPayment";
import { EMPTY_CARD, useCards } from "../../../contexts/CardsContext";
import { useSelectedResult } from "../../../contexts/SelectedResultContext";

const PaymentSplit = ({
  handlingFee,
  commission,
  priceToPay,
  setPriceToPay,
  setCardPaid,
  setPaymentLinks,
  paymentLinks,
  setPaymentLinksTotalToPay,
}) => {
  const { cards, updateCards } = useCards();
  // const { t } = useTranslation();

  const { ourSavedLDSOrder, setOurSavedLDSOrder } = useSelectedResult();

  React.useEffect(() => {
    if (ourSavedLDSOrder) {
      if (Array.isArray(ourSavedLDSOrder.payments)) {
        setPaymentLinks(ourSavedLDSOrder.payments);
        setPaymentLinksTotalToPay(
          ourSavedLDSOrder.payments
            .filter((payment) => payment.paid === true)
            .reduce((acc, cur) => acc + cur.actualAmount, 0)
        );
        if (cards.length < ourSavedLDSOrder.payments.length) {
          const tempCards = [...cards];
          for (
            let i = cards.length;
            i < ourSavedLDSOrder.payments.length;
            i++
          ) {
            tempCards.push({ ...EMPTY_CARD });
          }
          updateCards(tempCards);
        }
      }
    }
  }, [ourSavedLDSOrder]);

  function renderCards() {
    return cards.map(({ orderReqData }, index) => (
      <div key={index}>
        <CardPayment
          cardIndex={index}
          orderReqData={orderReqData}
          handlingFee={handlingFee}
          commission={commission}
          priceToPay={priceToPay}
          savePriceToPay={setPriceToPay}
          setCardPaid={setCardPaid}
          paymentLink={paymentLinks[index] || null}
          setOrderReqData={setOurSavedLDSOrder}
          currentOrderId={ourSavedLDSOrder?.id || ""}
        />
      </div>
    ));
  }

  return (
    <div
      className="payment-split"
      style={{ display: "flex", flexDirection: "column", gap: 10 }}
    >
      {renderCards()}
    </div>
  );
};

export default PaymentSplit;
