import React from "react";
import { useTranslation } from "react-i18next";
import "./Supplement.scss";
import { useSelectedResult } from "../../contexts/SelectedResultContext";

const Supplement = ({ disabled }) => {
  const { getSupplementsAsType, setLdsSupplement, ldsSupplement, ilsToUsd } =
    useSelectedResult();
  const { t } = useTranslation();

  const headers = [
    { text: "" },
    { text: t("packages.flight") },
    { text: t("packages.passenger") },
    { text: t("packages.baggage") },
    { text: t("packages.price") },
  ];

  const onClickSupplement = (supp) => {
    if (disabled) return;
    console.log(ldsSupplement);
    setLdsSupplement((prevState) => ({
      ...prevState,
      [supp["@seqNum"]]: {
        ...prevState[supp["@seqNum"]],
        checked: !supp.checked,
      },
    }));
  };

  return (
    <div className="tw-w-full">
      <div className="tw-w-full tw-overflow-x-auto">
        <div className="tw-flex tw-flex-col">
          <div className="tw-flex tw-bg-gray-200 tw-p-2">
            {headers.map(({ text }, index) => (
              <div
                className="tw-flex-1 tw-text-center tw-font-bold"
                key={`selector-${index}`}
              >
                {text}
              </div>
            ))}
          </div>
          {Object.entries(getSupplementsAsType()).map(
            ([type, suppList], index) => (
              <React.Fragment key={index}>
                <div className="tw-flex tw-bg-gray-100 tw-p-2">
                  <div className="tw-flex-1 tw-font-bold" colSpan="5">
                    <h2>{t(type)}</h2>
                  </div>
                </div>
                {suppList
                  .filter((supp) => !disabled || supp.checked)
                  .map((supp) => (
                    <div
                      className={`tw-flex tw-p-2 ${supp.checked ? "tw-bg-green-100" : "tw-bg-red-100"} tw-cursor-pointer`}
                      key={supp["@seqNum"]}
                      id={supp["@seqNum"]}
                      onClick={() => onClickSupplement(supp)}
                    >
                      <div className="tw-flex-1">
                        {disabled ? (
                          ""
                        ) : (
                          <button
                            className={`button button-small ${supp.checked ? "red-button" : "green-button"}`}
                          >
                            {supp.checked
                              ? t("packages.remove")
                              : t("packages.add")}
                          </button>
                        )}
                      </div>
                      <div className="tw-flex-1 tw-text-center">
                        {supp.flight.fltRef === "-2" ? (
                          <div>ROUNDTRIP</div>
                        ) : (
                          <div>
                            {supp.flight.depCity} <span>&rarr;</span>{" "}
                            {supp.flight.arrCity}
                          </div>
                        )}
                      </div>
                      <div className="tw-flex-1 tw-text-center">
                        {supp.pax.firstname} {supp.pax.lastname}
                      </div>
                      <div className="tw-flex-1 tw-text-center">
                        {supp.description} - {supp.weightText}
                      </div>
                      <div className="tw-flex-1 tw-text-center">
                        {supp.ourFare}₪ ({ilsToUsd(supp.ourFare)}$)
                      </div>
                    </div>
                  ))}
              </React.Fragment>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Supplement;
