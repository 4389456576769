import React, { useState, useEffect } from "react";

// Languages
import { useTranslation } from "react-i18next";

// CSS
import "./searchagent.css";

// Components
import DataBox from "../../../Components/Agent/SearchAgent/DataBox/databox";
import UpComingOrders from "../../../Components/Agent/SearchAgent/UpComingOrders/upcomingorders";

// Icons
import AvailableProfit from "../../../assets/images/ordersummery/dollar-symbol-blue.png";
import FutureCommissionIcon from "../../../assets/images/ordersummery/money-bag-orange.png";
import OrdersPaymentsIcon from "../../../assets/images/ordersummery/shopping-list-green.png";
import HistoryIcon from "../../../assets/images/ordersummery/history.svg";

// Services
import {
  getAvailableOrdersCommission,
  getOrdersCommissionHistory,
  getFinanceData,
  getFutureOrdersCommission,
} from "../../../services/fhpService";
import { convertPrice } from "../../../services/currencyService";
import FutureCommssion from "../../../Components/Agent/SearchAgent/FutureCommission/futurecommission";
import AvailableCommission from "../../../Components/Agent/SearchAgent/availableCommission/availableCommssion";
import { Loader } from "../../../Components/Loader/Loader";
import CommissionHistory from "../../../Components/Agent/SearchAgent/commissionHistory/commissionHistory";
// import { Title } from "../../../Components/Text";

const SearchAgent = () => {
  // Transelator
  const { t } = useTranslation();

  // States
  const [futureCommission, setFutureCommission] = useState([]);
  const [availableCommission, setAvailableCommission] = useState([]);
  const [historyCommission, setHistoryCommission] = useState([]);
  const [financeData, setFinanceData] = useState();
  const [loading, setLoading] = useState(true);
  const [futureLoading, setFutureLoading] = useState(true);
  const [availableLoading, setAvailableLoading] = useState(true);
  const [financeLoading, setFinanceLoading] = useState(true);
  const [historyLoading, setHistoryLoading] = useState(true);
  const [selectedComponent, setSelectedComponent] = useState("orderspayments");

  useEffect(() => {
    const getOrdersFromServer = async () => {
      // Get agent's email
      let agentEmail = JSON.parse(localStorage.getItem("UserInfo")).email;

      // Build the data and get the orders
      let data = {
        email: agentEmail,
      };
      setFutureLoading(true);
      let ordersData = await getFutureOrdersCommission(data);
      setFutureLoading(false);
      setFutureCommission(ordersData?.data);
    };

    const getAvailableCommission = async () => {
      // Get agent's email
      let agentEmail = JSON.parse(localStorage.getItem("UserInfo")).email;

      let data = {
        email: agentEmail,
      };
      setAvailableLoading(true);
      let ordersData = await getAvailableOrdersCommission(data);
      setAvailableLoading(false);
      setAvailableCommission(ordersData?.data);
    };

    const getHistoryCommission = async () => {
      // Get agent's email
      let agentEmail = JSON.parse(localStorage.getItem("UserInfo")).email;

      let data = {
        email: agentEmail,
      };
      setHistoryLoading(true);
      let ordersData = await getOrdersCommissionHistory(data);
      setHistoryLoading(false);
      setHistoryCommission(ordersData?.data);
    };

    const getFinanceDataFromServer = async () => {
      // Get agent's email
      let agentEmail = JSON.parse(localStorage.getItem("UserInfo")).email;

      // Build the data and get the finance info
      let data = {
        email: agentEmail,
      };
      setFinanceLoading(true);
      let financeData = await getFinanceData(data);
      setFinanceLoading(false);
      if (financeData?.data) {
        setFinanceData(financeData?.data);
      }
    };

    const fetchData = async () => {
      setLoading(true);
      await Promise.all([
        getFinanceDataFromServer(),
        getOrdersFromServer(),
        getAvailableCommission(),
        getHistoryCommission(),
      ]);
      setLoading(false);
    };

    fetchData();
  }, []);

  const components = {
    orderspayments: <UpComingOrders loading={loading} />,
    "price-futurecommission": (
      <FutureCommssion
        loading={futureLoading}
        futureCommission={futureCommission}
        setFutureCommission={setFutureCommission}
      />
    ),
    "price-availableprofit": (
      <AvailableCommission
        loading={availableLoading || loading}
        availableCommission={availableCommission}
        possibleProfit={
          financeData?.possibleProfit ? financeData.possibleProfit : 0
        }
      />
    ),
    "price-historycommission": (
      <CommissionHistory
        loading={historyLoading}
        commissionHistory={historyCommission}
      />
    ),
  };

  return (
    <>
      <div
        className="tw-flex tw-flex-col lg:tw-items-center lg:tw-px-[5%] lg:tw-mx-auto"
        style={{ height: "calc(100vh - 100px)" }}
      >
        {/* Agent info */}
        <div
          className="databox-container tw-flex tw-justify-between"
          style={{ marginTop: "20px", marginBottom: "20px" }}
        >
          <DataBox
            idOfBox={"orderspayments"}
            title={t("searchagent.orderspayments")}
            value={
              financeData && !financeLoading ? (
                financeData.comingOrders
              ) : (
                <Loader />
              )
            }
            iconSrc={OrdersPaymentsIcon}
            color={"green"}
            onClick={() => setSelectedComponent("orderspayments")}
          ></DataBox>
          <DataBox
            idOfBox={"price-futurecommission"}
            title={t("searchagent.futurecommission")}
            value={
              futureCommission && !futureLoading ? (
                convertPrice(
                  futureCommission.reduce((prev, curr) => {
                    return prev + curr.commission;
                  }, 0)
                )
              ) : (
                <Loader />
              )
            }
            iconSrc={FutureCommissionIcon}
            color={"orange"}
            onClick={() => setSelectedComponent("price-futurecommission")}
          ></DataBox>
          <DataBox
            idOfBox={"price-availableprofit"}
            title={t("searchagent.availableprofit")}
            value={
              availableCommission &&
              financeData &&
              !availableLoading &&
              !financeLoading ? (
                convertPrice(
                  availableCommission?.reduce(
                    (prev, curr) => {
                      return prev + curr.commission;
                    },
                    financeData?.possibleProfit ? financeData.possibleProfit : 0
                  )
                )
              ) : (
                <Loader />
              )
            }
            iconSrc={AvailableProfit}
            color={"blue"}
            onClick={() => setSelectedComponent("price-availableprofit")}
          ></DataBox>
          <DataBox
            idOfBox={"price-historycommission"}
            title={t("searchagent.historycommission")}
            value={
              historyCommission && !historyLoading ? (
                convertPrice(
                  historyCommission.reduce((prev, curr) => {
                    return prev + curr.commission;
                  }, 0)
                )
              ) : (
                <Loader />
              )
            }
            iconSrc={HistoryIcon}
            color={"black"}
            onClick={() => setSelectedComponent("price-historycommission")}
          ></DataBox>
        </div>
        <div className="tw-flex tw-flex-row  tw-w-full tw-mx-auto tw-h-[65%] tw-mt-0  tw-mb-1">
          <div className="tw-w-full tw-h-full tw-bg-white tw-rounded-[5px] tw-shadow-[0px_2px_4px_#cbc8c8]">
            <div className="lg:tw-w-[80%] tw-mx-auto tw-h-[90%]">
              {components[selectedComponent]}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchAgent;
