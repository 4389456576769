import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SearchResults from "../SearchAgent/SearchResults/searchresults";
import SearchBarSelector from "../SearchBars/searchbarselector";
import NoResults from "../../../assets/images/icons/no-results.png";
import ChangeUser from "../../../assets/images/icons/change-user.png";
import SearchOptions from "./SearchOptions/SearchOptions";
import { useSearch } from "../../../contexts/SearchContext";
import { Loader } from "../../Loader/Loader";
import "./priceoffercomponent.css";
import PassangerData from "./subComponents/PassangerData/passangerdata";
import OrderSummery from "../OrderSummery/ordersummery";
import Timer from "../../../Pages/Timer/Timer";
import { useOrder } from "../../../contexts/OrderContext";

const PriceOfferComponent = ({ changeClient, clientsInfo }) => {
  const { currentHotelOrder } = useOrder();
  const { loading, searchResults, isSearched } = useSearch();
  const { selectedObject } = useParams(); // Checks if picked a room / flight ...
  const { t } = useTranslation();

  function renderResults() {
    if (loading) {
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader size="large" />
        </div>
      );
    }
    if (searchResults === null) {
      return (
        <div className="price-offer-no-results-wrapper">
          <img
            className="price-offer-no-results-img"
            src={NoResults}
            alt="No Results"
          ></img>
        </div>
      );
    }
    return <SearchResults />;
  }

  // Thats means that the user selected a room
  if (selectedObject) {
    return (
      <>
        <div className="tw-px-[5%]">
          {/* Search Results */}
          <div>
            {/* Search Bar */}
            {/*Search Results / Order Summery*/}
            {/* <div>
              <Timer />
            </div> */}
            <div className="price-offer-content-wrapper tw-flex tw-flex-col-reverse lg:tw-flex-row tw-flex-nowrap tw-w-full tw-h-full">
              {currentHotelOrder ? (
                <PassangerData OrderData={currentHotelOrder} />
              ) : (
                <div className="price-offer-passenger-error">
                  Somthing Went Wrong
                </div>
              )}

              <div
                className={`price-offer-data-wrappers price-offer-order-details-wrapper lg:tw-w-[45%]`}
              >
                <OrderSummery clientsInfo={clientsInfo}></OrderSummery>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="tw-py-[20px] lg:tw-p-[20px] lg:tw-w-[calc(100%_-_40px)] tw-mx-auto tw-h-[calc(100%_-_40px)] tw-justify-center">
        {/* Search Results */}
        <div
          className="price-offer-content-wrapper tw-flex lg:tw-flex-row tw-flex-nowrap tw-w-full tw-h-full"
          style={{ flexDirection: "column" }}
        >
          <div className="searchAgent-search-bar-option-wrapper">
            <div className="price-offer-change-user-search-option-wrapper">
              {/* Search Options / Flight ways */}
              <div className="price-offer-search-option-wrapper-wrapper">
                <SearchOptions />
              </div>

              <div className="priceOffer-change-client-wrapper">
                <div
                  onClick={changeClient}
                  className="priceOffer-change-client"
                >
                  <img
                    className="priceOffer-change-client-img"
                    src={ChangeUser}
                    alt="Change client"
                  ></img>
                  <span className="priceOffer-change-client-title">
                    {t("priceoffer.editclientsinfo")}
                  </span>
                </div>
              </div>
            </div>

            {/* Search Bar Selector */}
            <div className="searchAgent-search-bar-wrapper">
              <SearchBarSelector />
            </div>
          </div>
          <div className="searchAgent-search-result-wrapper">
            <SearchResults />
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceOfferComponent;
