import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import "./Filters.scss";
import { Dropdown, Input, InputNumber } from "antd";

const TextFilterNumber = ({
  onFilterChange,
  filterName,
  placeHolder,
  value,
  searchResults,
}) => {
  const [isExpand, setIsExpand] = useState(false);
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const changeExpand = () => {
    setIsExpand((prevState) => !prevState);
    onFilterChange(internalValue);
  };

  const nights = (searchResults || [])
    .map((item) => Number(item?.fullDeal?.hotel?.nights || item.tour.nights))
    .filter((item, index, self) => self.indexOf(item) === index)

  const minNights = Math.min(...nights) || 0;
  const maxNights = Math.max(...nights) || 0;

  return (
    <div className="hotelFilters-filter-wrapper">
      <div
        onClick={changeExpand}
        className={`hotelFilters-filters-title-wrapper ${
          document.documentElement.dir === "ltr"
            ? "direction-rtl"
            : "direction-ltr"
        }`}
      >
        <span className="hotelFilters-filters-title">{filterName}</span>
        {isExpand ? (
          <IoIosArrowUp className="hotelFilters-filters-arrow" />
        ) : (
          <IoIosArrowDown className="hotelFilters-filters-arrow" />
        )}
      </div>

      {isExpand && (
        <div className="hotelfilters-price-wrapper">
          <InputNumber
            min={minNights}
            max={maxNights}
            value={internalValue}
            onChange={(value) => {
              setInternalValue(value);
              onFilterChange(value);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default TextFilterNumber;
