import React from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import classNames from "classnames";
import "./SummaryFooter.scss";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import { Loader } from "../Loader/Loader";
import { useSelectedResult } from "../../contexts/SelectedResultContext";

const SummaryFooter = ({
  moveNextStage,
  beforePayment,
  nextStageDisabled,
  loading,
}) => {
  const { ourPrice, ilsToUsd } = useSelectedResult();

  const { t } = useTranslation();

  return (
    <Tooltip
      title={nextStageDisabled ? <h1>{nextStageDisabled}</h1> : undefined}
    >
      <div className="summary-footer tw-pt-2 tw-pb-2 tw-pr-3 tw-pl-3">
        <div className="price-to-pay tw-text-sm">
          <div className="tw-hidden lg:tw-block">
            {t("packages.totalToPay")}:
          </div>
          {ourPrice?.priceWithCreditCardFee ? (
            <span>
              {ourPrice.priceWithCreditCardFee}₪ (
              {ilsToUsd(ourPrice.priceWithCreditCardFee)}$)
            </span>
          ) : (
            <SkeletonLoader
              style={{ width: 70, height: 20, marginRight: 10 }}
            />
          )}
        </div>

        {loading ? (
          <Loader />
        ) : beforePayment ? (
          <div
            className="buttons tw-flex-col lg:tw-flex-row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <button
              key="pay-now"
              onClick={nextStageDisabled ? null : () => moveNextStage("now")}
              className={classNames(
                "order-package-btn tw-text-sm lg:tw-text-xl tw-p-1 tw-w-[100%] lg:tw-w-[200px]",
                {
                  disabled: nextStageDisabled,
                }
              )}
            >
              {t("packages.pay-now")}
            </button>
            <button
              key="pay-later"
              onClick={nextStageDisabled ? null : () => moveNextStage("later")}
              className={classNames(
                "order-package-btn tw-text-sm lg:tw-text-xl tw-w-[100%] tw-p-1 lg:tw-w-[200px]",
                {
                  disabled: nextStageDisabled,
                }
              )}
            >
              {t("packages.pay-later")}
            </button>
          </div>
        ) : (
          <button
            onClick={nextStageDisabled ? null : moveNextStage}
            className={classNames(
              "order-package-btn tw-text-sm lg:tw-text-xl tw-w-[100px] lg:tw-w-[200px]",
              {
                disabled: nextStageDisabled,
              }
            )}
          >
            {t("packages.continue")}
          </button>
        )}
      </div>
    </Tooltip>
  );
};

export default SummaryFooter;
