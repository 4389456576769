import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Gallary
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// Languages
import { useTranslation } from "react-i18next";

// CSS
import "./sameroom.css";

// Images
import CoinIcon from "../../../../../../assets/images/icons/coin.png";
import NoBeds from "../../../../../../assets/images/icons/bed.png";

// Services
import { convertPrice } from "../../../../../../services/currencyService";

// React Icons
import { FaBed } from "react-icons/fa";
import { LiaUtensilsSolid } from "react-icons/lia";
import { TfiHandPointLeft, TfiHandPointRight } from "react-icons/tfi";
import { AiOutlineCheck } from "react-icons/ai";
import ImagesCarousel from "../../../../../../Components/ImagesCrousel/ImagesCarousel";
import { parsePriceToShow } from "../../../../../../utils/OrderService";
import { useOrder } from "../../../../../../contexts/OrderContext";
import { useSelectedResult } from "../../../../../../contexts/SelectedResultContext";

function SameRoom({ sameRoom, currentSearch, hotelInfo, checkInOutInfo }) {
  const { ilsToUsd } = useSelectedResult();
  const { currentHotelOrder, setCurrentHotelOrder } = useOrder();
  // Navigator
  const navigate = useNavigate();

  // Translator
  const { t } = useTranslation();

  // States
  const [isZoom, setIsZoom] = useState(false);

  // --- Functions

  // Order Room
  const orderRoom = (e, selectedRoom, selectedPackage) => {
    e.preventDefault();

    selectedPackage["roomNames"] = selectedRoom.roomNames;

    const packageRoomHotelData = {
      searchPhrase: currentSearch,
      selectedHotel: hotelInfo,
      selectedHotelPackage: selectedPackage,
      checkInOutInfo: checkInOutInfo,
      selectedRoom: selectedRoom,
    };

    // Save in local storage the order
    setCurrentHotelOrder(packageRoomHotelData);

    navigate(
      "/p/dashboard/searchoffer/" +
        packageRoomHotelData.selectedHotelPackage.packageID
    );
  };

  // Actions
  const zoomImage = () => {
    setIsZoom(!isZoom);
  };

  return (
    <div className="sameRoom-wrapper">
      {sameRoom && (
        <div className="roomDisplayer-room-package ">
          {/* Rooms Pacakge Header */}
          <div className="roomDisplayer-room-package-header lg:tw-flex lg:tw-flex-row">
            {/* Room Images */}
            <div className="lg:tw-w-[30%]">
              <ImagesCarousel images={sameRoom.images} />
            </div>

            {/* Room Describtion */}
            <div className="roomDisplayer-room-package-describtion lg:tw-w-[70%]">
              {/* Room Title */}
              <div className="roomDisplayer-room-package-text">
                <label className="roomDisplayer-room-package-title">
                  {sameRoom.SimilarRooms ? (
                    <span className="same-room-duplication">
                      &times;{sameRoom.roomNames.length}
                    </span>
                  ) : (
                    ""
                  )}

                  {sameRoom.roomNames[0]}
                </label>

                <label className="roomDisplayer-room-package-sub-title">
                  {sameRoom.bedTypes && sameRoom.bedTypes.length > 0
                    ? sameRoom.bedTypes[0].charAt(0) +
                      sameRoom.bedTypes[0].slice(1).toLowerCase() +
                      " Bed"
                    : ""}
                </label>
              </div>

              {/* Room Amenatis */}
              <div className="roomDisplayer-room-package-amenatis">
                <label className="roomDisplayer-room-package-amenatis-title">
                  {t("hotelprofile.facilities")}
                </label>

                <div className="roomDisplayer-room-package-amenatis-content">
                  {sameRoom.facilities.map((facility, index) => (
                    // {sameRoom.facilities.slice(0, 5).map((facility, index) => (
                    // <div key={index}>
                    //     {facility}
                    // </div>
                    <div
                      key={index}
                      className="hotelProfile-content-all-amenatis-check-wrapper"
                    >
                      <label>{facility}</label>
                      <AiOutlineCheck className="hotelProfile-content-all-amenatis-check" />
                    </div>
                  ))}

                  {/* <label className='roomDisplayer-room-package-amenatis-content-showall-btn'>הצג הכל</label> */}
                </div>
              </div>
            </div>
          </div>

          {/* Rooms Pacakge Content */}
          <div className="roomDisplayer-room-wrapper tw-overflow-y-auto tw-overflow-x-hidden">
            {/* Rooms Displayer Wrapper */}
            <div className="roomDisplayer-room-all-rooms ">
              {/* Room Information */}
              <div
                className="roomDisplayer-room-object-info-wrapper lg:tw-w-[90%]  "
                style={sameRoom.packages.length > 3 ? { height: "30vh" } : {}}
              >
                {sameRoom.packages.map((roomPkg, index) => (
                  <table
                    key={index}
                    className="roomDisplayer-room-content-wrapper"
                  >
                    <tbody>
                      <tr className="lg:tw-p-[10px]">
                        <td className="roomDisplayer-object-basis">
                          {roomPkg.roomBasis.length > 0 && (
                            <div className="roomDisplayer-object-basis-item ">
                              {roomPkg.roomBasis.split(" ").pop() === "RO" ? (
                                <FaBed className="roomDisplayer-object-basis-img tw-hidden lg:tw-block"></FaBed>
                              ) : (
                                <LiaUtensilsSolid className="roomDisplayer-object-basis-img tw-hidden lg:tw-block"></LiaUtensilsSolid>
                              )}

                              <label className="roomDisplayer-object-basis-title tw-text-xs lg:tw-text-base">
                                {t(
                                  `hotelprofile.${roomPkg.roomBasis
                                    .split(" ")
                                    .pop()}`
                                )}
                              </label>
                            </div>
                          )}

                          {sameRoom.bedTypes &&
                            sameRoom.bedTypes.length > 0 && (
                              <div className="roomDisplayer-object-basis-item ">
                                <FaBed className="roomDisplayer-object-basis-img tw-hidden lg:tw-block"></FaBed>

                                <label className="roomDisplayer-object-basis-title  tw-text-xs lg:tw-text-base">
                                  {sameRoom.bedTypes[0]}
                                </label>
                              </div>
                            )}
                        </td>

                        <td className="roomDisplayer-object-room-border"></td>

                        {/* Free Cancellation */}
                        <td className="suggestedRoom-object-cancelation">
                          <div className="suggestedRoom-object-cancelation-wrapper">
                            <label
                              className={`roomDisplayer-object-cancelation-title ${
                                roomPkg.refundability
                                  ? "suggestedRoom-object-cancelation-title-refund"
                                  : "suggestedRoom-object-cancelation-title-no-refund"
                              }`}
                            >
                              {roomPkg.refundability
                                ? t("hotelprofile.freecancelation")
                                : t("hotelprofile.nofreecancelation")}
                            </label>

                            <label className="suggestedRoom-object-cancelation-sub-title">
                              {roomPkg.refundability ? (
                                <span>
                                  {`${t("hotelprofile.freecancelationtext")}`}{" "}
                                  <span className="sameRoom-object-cancelation-sub-title-date">
                                    {roomPkg.refundableUntil}
                                  </span>{" "}
                                </span>
                              ) : (
                                t("hotelprofile.nofreecancelationtext")
                              )}
                            </label>
                          </div>

                          <img
                            className="suggestedRoom-object-cancelation-img tw-hidden lg:tw-block"
                            src={CoinIcon}
                            alt="Coin Icon"
                          ></img>
                        </td>

                        <td className="roomDisplayer-object-room-border"></td>

                        {/* Room Pricing */}
                        <td className="roomDisplayer-object-pricing">
                          <label
                            id="price-same"
                            className="suggestedRoom-object-pricing-title lg:tw-text-lg"
                            // origPrice={roomPkg.finalPrice}
                          >
                            {convertPrice(
                              parsePriceToShow(
                                roomPkg.ourPrice
                                  .priceWithCommissionAndCreditCardFee
                              )
                            )}{" "}
                            (
                            {ilsToUsd(
                              parsePriceToShow(
                                roomPkg.ourPrice
                                  .priceWithCommissionAndCreditCardFee
                              )
                            )}
                            $)
                          </label>

                          <label className="suggestedRoom-object-pricing-sub-title">
                            {roomPkg.includesTaxes
                              ? t("hotelprofile.includetaxes")
                              : t("hotelprofile.dontincludetaxes")}
                          </label>
                        </td>

                        <td className="roomDisplayer-object-room-border"></td>

                        {/* Package Pricing */}
                        <td className="roomDisplayer-object-select-package">
                          {/* Fot the first package - the cheapest */}
                          {index === 0 && (
                            <div className="sameRoom-chipest-wrapper ">
                              {document.documentElement.dir === "rtl" ? (
                                <TfiHandPointLeft className=" tw-hidden lg:tw-block" />
                              ) : (
                                <TfiHandPointRight className=" tw-hidden lg:tw-block" />
                              )}

                              <label>{t("hotelprofile.cheapest")}</label>
                            </div>
                          )}

                          <button
                            onClick={(e) => orderRoom(e, sameRoom, roomPkg)}
                            className="suggestedRoom-object-pricing-btn"
                            type="submit"
                          >
                            {t("hotelprofile.ordernow")}
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SameRoom;
