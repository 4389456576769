import React, { createContext, useContext, useEffect, useState } from "react";
import { round2, useAppContext } from "./AppContext";

const OrderContext = createContext();
const CURRENT_HOTEL_ORDER = "currentHotelOrder";

export const OrderProvider = ({ children }) => {
  const { getCreditCardFeePercent, getAgentCommissionPercent, isGlobalConstantLoaded } = useAppContext();

  const [currentHotelOrder, _setCurrentHotelOrder] = useState(null);

  const setHandleFeeData = (type, value) => {
    const ourPrice = currentHotelOrder.ourPrice;
    ourPrice.handleFeeData = {
      type: type,
      value: value
    }

    currentHotelOrder.ourPrice = ourPrice;
    currentHotelOrder.ourPrice = calcOurTotalPrice(currentHotelOrder);

    setCurrentHotelOrder(currentHotelOrder);
  };

  const setCurrentHotelOrder = (currentHotelOrder) => {
    // console.log("isGlobalConstantLoaded",isGlobalConstantLoaded)
    if (currentHotelOrder) {
      currentHotelOrder.ourPrice = calcOurTotalPrice(currentHotelOrder);
    }
    _setCurrentHotelOrder({ ...currentHotelOrder });
  };

  useEffect(() => {
    if (!currentHotelOrder) return;
    // Save the order to localStorage whenever it changes
    localStorage.setItem(
      CURRENT_HOTEL_ORDER,
      JSON.stringify(currentHotelOrder)
    );
  }, [currentHotelOrder]);

  useEffect(() => {
    if (currentHotelOrder) {
      currentHotelOrder.ourPrice = calcOurTotalPrice(currentHotelOrder);
      setCurrentHotelOrder(currentHotelOrder);
    }
  }, [isGlobalConstantLoaded]);

  const removeCurrentHotelOrder = () => {
    setCurrentHotelOrder(null);
  };

  const calculateHandleFee = (ourPrice) => {
    const priceWithCommission = ourPrice.priceWithCommission;

    const handleFeeData = ourPrice.handleFeeData;

    if (!handleFeeData || !handleFeeData.value || !handleFeeData.type) return 0;

    let handlingFee = handleFeeData.value;

    if (handleFeeData.type === "%")
      handlingFee = (priceWithCommission * handleFeeData.value) / 100;

    return handlingFee;
  };

  const calcOurTotalPrice = (currentHotelOrder) => {
    let ourPrice = currentHotelOrder.ourPrice;
    if (!ourPrice) {
      if (!currentHotelOrder.selectedHotelPackage) return null;
      ourPrice = currentHotelOrder.selectedHotelPackage.ourPrice;
    }

    ourPrice.handlingFee = calculateHandleFee(ourPrice);

    ourPrice.agentCommission = getAgentCommissionPercent() / 100 * ourPrice.priceWithCommission;

    ourPrice.priceWithHandleFee =
      ourPrice.priceWithCommission + ourPrice.handlingFee;

    ourPrice.creditCardFee = (ourPrice.priceWithCommission + ourPrice.handlingFee + ourPrice.agentCommission) * (getCreditCardFeePercent() / 100);

    ourPrice.priceWithCreditCardFee = round2(
      ourPrice.priceWithHandleFee + ourPrice.creditCardFee
    );

    return ourPrice;
  };

  useEffect(() => {
    const data = localStorage.getItem(CURRENT_HOTEL_ORDER);
    setCurrentHotelOrder(data && data.length > 0 ? JSON.parse(data) : null);
  }, []);

  return (
    <OrderContext.Provider
      value={{
        currentHotelOrder,
        setCurrentHotelOrder,
        removeCurrentHotelOrder,
        setHandleFeeData
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export const useOrder = () => {
  const context = useContext(OrderContext);
  if (context === undefined) {
    throw new Error("useOrder must be used within a OrderProvider");
  }
  return context;
};

export default OrderContext;
